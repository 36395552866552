import React, { useState, useEffect } from 'react';
import { Card, List, Button, Input, Modal, Form, message, Popconfirm, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { getWikiEntries, createWikiEntry, updateWikiEntry, deleteWikiEntry } from '../apiService';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const Wiki = () => {
  const [entries, setEntries] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isAdmin = true; // Set this based on user role. You might want to fetch this from a user context or props.

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    setLoading(true);
    try {
      const response = await getWikiEntries();
      setEntries(response);
    } catch (error) {
      console.error('Error fetching wiki entries:', error);
      message.error('Failed to load wiki entries');
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async (values) => {
    try {
      await createWikiEntry(values);
      message.success('Wiki entry created successfully');
      setModalVisible(false);
      form.resetFields();
      fetchEntries();
    } catch (error) {
      console.error('Error creating wiki entry:', error);
      message.error('Failed to create wiki entry');
    }
  };

  const handleUpdate = async (values) => {
    try {
      await updateWikiEntry(editingEntry.id, values);
      message.success('Wiki entry updated successfully');
      setModalVisible(false);
      setEditingEntry(null);
      form.resetFields();
      fetchEntries();
    } catch (error) {
      console.error('Error updating wiki entry:', error);
      message.error('Failed to update wiki entry');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteWikiEntry(id);
      message.success('Wiki entry deleted successfully');
      fetchEntries();
    } catch (error) {
      console.error('Error deleting wiki entry:', error);
      message.error('Failed to delete wiki entry');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <Title level={2}>Wiki</Title>
        {isAdmin && (
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
            Create New Entry
          </Button>
        )}
      </div>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
        dataSource={entries}
        loading={loading}
        renderItem={(entry) => (
          <List.Item key={entry.id}>
            <Card
              className="wiki-card"
              title={<Title level={4}>{entry.title}</Title>}
              extra={
                isAdmin && (
                  <>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setEditingEntry(entry);
                        form.setFieldsValue(entry);
                        setModalVisible(true);
                      }}
                    />
                    <Popconfirm
                      title="Are you sure you want to delete this entry?"
                      onConfirm={() => handleDelete(entry.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                  </>
                )
              }
            >
              <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>
                <ReactMarkdown>{entry.content}</ReactMarkdown>
              </Paragraph>
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title={editingEntry ? "Edit Wiki Entry" : "Create Wiki Entry"}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingEntry(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={editingEntry ? handleUpdate : handleCreate}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content (Markdown supported)"
            rules={[{ required: true, message: 'Please input the content!' }]}
          >
            <TextArea rows={6} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingEntry ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Wiki;