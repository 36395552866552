import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, List, Image, Input, Button, message, Upload, Popconfirm, Tag, Typography, Row, Col, Tooltip, Modal } from 'antd';
import { LikeOutlined, CommentOutlined, PushpinOutlined, UploadOutlined, SendOutlined, DownloadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { getSocialUpdates, createSocialUpdate, likeSocialUpdate, unlikeSocialUpdate, pinSocialUpdate, unpinSocialUpdate, getComments, addComment, getAllUsers, deleteSocialUpdate } from '../apiService';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import './SocialUpdates.css';

const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;

const SocialUpdates = ({ currentUser }) => {
  const [updates, setUpdates] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newUpdate, setNewUpdate] = useState('');
  const [fileList, setFileList] = useState([]);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const isAdmin = currentUser.role === 'administrator'; // Adjust this based on your role structure

  useEffect(() => {
    fetchUpdates();
    fetchUsers();
  }, []);

  const fetchUpdates = async () => {
    setLoading(true);
    try {
      const response = await getSocialUpdates();
      setUpdates(response);
    } catch (error) {
      console.error('Error fetching social updates:', error);
      message.error('Failed to load team updates');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        const formattedUsers = response.users.map(user => ({
          id: user.id,
          name: user.display_name || user.username || user.email,
          profileImage: user.profile_image_url,
          role: user.role || 'User'
        }));
        setUsers(formattedUsers);
      } else {
        console.error('Unexpected user data format:', response);
        message.error('Failed to load users: Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const handleCreateUpdate = async () => {
    try {
      const updateData = {
        content: newUpdate,
        file: fileList.length > 0 ? fileList[0].originFileObj : null,
      };

      await createSocialUpdate(updateData);
      message.success('Team update created successfully');
      setNewUpdate('');
      setFileList([]);
      fetchUpdates();
    } catch (error) {
      console.error('Error creating team update:', error);
      message.error('Failed to create team update');
    }
  };

  const handleLike = async (updateId, isLiked) => {
    try {
      if (isLiked) {
        await unlikeSocialUpdate(updateId);
      } else {
        await likeSocialUpdate(updateId);
      }
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId
            ? { ...update, is_liked: !isLiked, likes_count: isLiked ? update.likes_count - 1 : update.likes_count + 1 }
            : update
        )
      );
    } catch (error) {
      console.error('Error liking/unliking update:', error);
      message.error('Failed to like/unlike update');
    }
  };

  const handlePin = async (updateId) => {
    try {
      await pinSocialUpdate(updateId);
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId ? { ...update, pinned: true } : update
        )
      );
      message.success('Update pinned successfully');
    } catch (error) {
      console.error('Error pinning update:', error);
      message.error('Failed to pin update');
    }
  };

  const handleUnpin = async (updateId) => {
    try {
      await unpinSocialUpdate(updateId);
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId ? { ...update, pinned: false } : update
        )
      );
      message.success('Update unpinned successfully');
    } catch (error) {
      console.error('Error unpinning update:', error);
      message.error('Failed to unpin update');
    }
  };

  const handleDelete = async (updateId) => {
    try {
      await deleteSocialUpdate(updateId);
      setUpdates(prevUpdates => prevUpdates.filter(update => update.id !== updateId));
      message.success('Update deleted successfully');
    } catch (error) {
      console.error('Error deleting update:', error);
      message.error('Failed to delete update');
    }
  };

  const handleCommentClick = async (update) => {
    setSelectedUpdate(update);
    try {
      const comments = await getComments(update.id);
      setSelectedUpdate({ ...update, comments });
      setCommentModalVisible(true);
    } catch (error) {
      console.error('Error fetching comments:', error);
      message.error('Failed to load comments');
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      message.error('Comment cannot be empty');
      return;
    }
    try {
      await addComment(selectedUpdate.id, { content: newComment });
      message.success('Comment added successfully');
      setNewComment('');
      const updatedComments = await getComments(selectedUpdate.id);
      setSelectedUpdate({ ...selectedUpdate, comments: updatedComments });
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === selectedUpdate.id
            ? { ...update, comments_count: update.comments_count + 1 }
            : update
        )
      );
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error('Failed to add comment');
    }
  };

  const handlePreview = (file) => {
    setPreviewFile(file);
    setPreviewVisible(true);
  };

  const renderDocumentPreview = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isPreviewable = ['pdf', 'doc', 'docx', 'xls', 'xlsx'].includes(fileExtension);

    return (
      <div className="document-preview">
        <FileOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
        <Text>{file.name}</Text>
        {isPreviewable && (
          <Button type="link" onClick={() => handlePreview(file)}>
            Preview
          </Button>
        )}
        <Button type="link" href={file.url} target="_blank" download>
          <DownloadOutlined /> Download
        </Button>
      </div>
    );
  };

  const renderUpdateContent = (update) => (
    <Card
      hoverable
      className={`w-full mb-4 ${update.pinned ? 'bg-blue-50' : ''}`}
      actions={[
        <Tooltip key="like" title={update.is_liked ? "Unlike" : "Like"}>
          <Button 
            icon={<LikeOutlined />} 
            onClick={() => handleLike(update.id, update.is_liked)}
            type={update.is_liked ? "primary" : "default"}
          >
            {update.likes_count} Like{update.likes_count !== 1 ? 's' : ''}
          </Button>
        </Tooltip>,
        <Tooltip key="comment" title="Comment">
          <Button icon={<CommentOutlined />} onClick={() => handleCommentClick(update)}>
            {update.comments_count} Comment{update.comments_count !== 1 ? 's' : ''}
          </Button>
        </Tooltip>,
        isAdmin && (
          update.pinned ? (
            <Popconfirm
              key="unpin"
              title="Are you sure you want to unpin this update?"
              onConfirm={() => handleUnpin(update.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Unpin">
                <Button icon={<PushpinOutlined />} type="primary">
                  Unpin
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Popconfirm
              key="pin"
              title="Are you sure you want to pin this update?"
              onConfirm={() => handlePin(update.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Pin">
                <Button icon={<PushpinOutlined />}>
                  Pin
                </Button>
              </Tooltip>
            </Popconfirm>
          )
        ),
        isAdmin && (
          <Popconfirm
            key="delete"
            title="Are you sure you want to delete this update?"
            onConfirm={() => handleDelete(update.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button icon={<DeleteOutlined />} danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
        ),
      ]}
    >
      <Card.Meta
        avatar={
          (update.user_id === currentUser.id ? currentUser.profile_image_url : update.user_avatar) && (
            <Image
              src={update.user_id === currentUser.id ? currentUser.profile_image_url : update.user_avatar}
              alt={`${update.user_name}'s profile image`}
              width={50}
              height={50}
              preview={false}
            />
          )
        }
        title={
          <div>
            <span>{update.user_name || (update.user_id === currentUser.id ? currentUser.display_name : 'Unknown User')}</span>
            {update.pinned && <Tag color="blue" className="ml-2">Important</Tag>}
          </div>
        }
        description={moment(update.created_at).fromNow()}
      />
      <Paragraph className="mt-4">
        <ReactMarkdown>{update.content}</ReactMarkdown>
      </Paragraph>
      {update.media_url && (
        <img src={update.media_url} alt="Update media" className="w-full mt-4 rounded-lg" />
      )}
      {update.document && renderDocumentPreview(update.document)}
    </Card>
  
  );

  const renderUserList = () => (
    <List
      itemLayout="horizontal"
      dataSource={users}
      renderItem={(user) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              user.profileImage && (
                <Image
                  src={user.profileImage}
                  alt={`${user.name}'s profile image`}
                  width={50}
                  height={50}
                  preview={false}
                />
              )
            }
            title={<a href={`/user/${user.id}`}>{user.name}</a>}
            description={user.role}
          />
        </List.Item>
      )}
    />
  );

  return (
    <div className="social-updates-container">
      <Row gutter={24}>
        <Col span={16}>
          <div className="mb-8">
            <Title level={2} className="page-header">In-House Team Updates</Title>
            <Card className="update-form">
              <TextArea
                rows={4}
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
                placeholder="What's on your mind?"
              />
              <div className="mt-4 flex justify-between items-center">
                <Upload
                  fileList={fileList}
                  onChange={({ fileList }) => setFileList(fileList)}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Upload File/Image/Video</Button>
                </Upload>
                <Button type="primary" onClick={handleCreateUpdate}>Post Update</Button>
              </div>
            </Card>
          </div>
          <List
            className="updates-list"
            dataSource={updates}
            loading={loading}
            renderItem={renderUpdateContent}
          />
        </Col>
        <Col span={8}>
          <Card title="Users" className="users-card">
            {renderUserList()}
          </Card>
        </Col>
      </Row>

      <Modal
  title="Comments"
  visible={commentModalVisible}
  onCancel={() => setCommentModalVisible(false)}
  footer={null}
>
  {selectedUpdate && (
    <>
      <List
        dataSource={selectedUpdate.comments}
        renderItem={(comment) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                comment.user_avatar && (
                  <Image
                    src={comment.user_avatar}
                    alt={`${comment.user_name}'s profile image`}
                    width={40}
                    height={40}
                    preview={false}
                  />
                )
              }
              title={comment.user_name}
              description={comment.content}
            />
          </List.Item>
        )}
      />
      <div className="mt-4 flex">
        <Input
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a comment..."
        />
        <Button type="primary" icon={<SendOutlined />} onClick={handleAddComment}>
          Send
        </Button>
      </div>
    </>
  )}
</Modal>

      <Modal
        title="Document Preview"
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width={800}
      >
        {previewFile && (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(previewFile.url)}&embedded=true`}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          ></iframe>
        )}
      </Modal>
    </div>
  );
};

SocialUpdates.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    role: PropTypes.string.isRequired,
    profile_image_url: PropTypes.string,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default SocialUpdates;