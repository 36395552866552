import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, List, Button, Input, message, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';

const { TextArea } = Input;

const UserNotes = ({ visible, onClose, token }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [newNote, setNewNote] = useState({ topic: '', content: '' });

  const api = axios.create({
    baseURL: 'https://niledutech.in/wp-json/ei-crm/v1',
    headers: { Authorization: `Bearer ${token}` }
  });

  useEffect(() => {
    if (visible) {
      fetchNotes();
    }
  }, [visible]);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const response = await api.get('/user-notes');
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching user notes:', error);
      message.error('Failed to load notes');
    } finally {
      setLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (!newNote.topic.trim() || !newNote.content.trim()) {
      message.error('Topic and content are required');
      return;
    }
    try {
      await api.post('/user-notes', newNote);
      message.success('Note added successfully');
      setNewNote({ topic: '', content: '' });
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleUpdateNote = async () => {
    if (!editingNote.topic.trim() || !editingNote.content.trim()) {
      message.error('Topic and content are required');
      return;
    }
    try {
      await api.put(`/user-notes/${editingNote.id}`, {
        topic: editingNote.topic,
        content: editingNote.content
      });
      message.success('Note updated successfully');
      setEditingNote(null);
      fetchNotes();
    } catch (error) {
      console.error('Error updating note:', error);
      message.error('Failed to update note');
    }
  };

  const handleDeleteNote = async (id) => {
    try {
      await api.delete(`/user-notes/${id}`);
      message.success('Note deleted successfully');
      fetchNotes();
    } catch (error) {
      console.error('Error deleting note:', error);
      message.error('Failed to delete note');
    }
  };

  return (
    <Drawer
      title="User Notes"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          <Input
            placeholder="New note topic"
            value={newNote.topic}
            onChange={(e) => setNewNote({ ...newNote, topic: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextArea
            rows={4}
            placeholder="New note content"
            value={newNote.content}
            onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNote}>
            Add Note
          </Button>
        </div>
        <List
          loading={loading}
          dataSource={notes}
          renderItem={(note) => (
            <List.Item
              actions={[
                <Button key="edit" icon={<EditOutlined />} onClick={() => setEditingNote(note)} />,
                <Popconfirm
                  key="delete"
                  title="Are you sure you want to delete this note?"
                  onConfirm={() => handleDeleteNote(note.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} danger />
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                title={note.topic}
                description={note.content}
              />
            </List.Item>
          )}
          style={{ flex: 1, overflowY: 'auto' }}
        />
      </div>
      {editingNote && (
        <div style={{ 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          right: 0, 
          background: 'white', 
          padding: '20px',
          borderTop: '1px solid #f0f0f0'
        }}>
          <Input
            value={editingNote.topic}
            onChange={(e) => setEditingNote({ ...editingNote, topic: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextArea
            rows={4}
            value={editingNote.content}
            onChange={(e) => setEditingNote({ ...editingNote, content: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <Button type="primary" icon={<SaveOutlined />} onClick={handleUpdateNote}>
            Update Note
          </Button>
          <Button onClick={() => setEditingNote(null)} style={{ marginLeft: '10px' }}>
            Cancel
          </Button>
        </div>
      )}
    </Drawer>
  );
};

UserNotes.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default UserNotes;