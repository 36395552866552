import axios from 'axios';

const API_BASE_URL = 'https://niledutech.in/wp-json/ei-crm/v1';
const AUTH_URL = 'https://niledutech.in/wp-json/jwt-auth/v1/token';
const USER_DETAILS_URL = 'https://niledutech.in/wp-json/ei-crm/v1/users/me/details';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      // Clear the invalid token
      localStorage.removeItem('jwtToken');
      
      try {
        // Attempt to refresh the token
        const refreshToken = localStorage.getItem('refreshToken'); // You'll need to store this when logging in
        if (refreshToken) {
          const response = await axios.post(`${AUTH_URL}/refresh`, { refresh_token: refreshToken });
          const newToken = response.data.token;
          localStorage.setItem('jwtToken', newToken);
          apiService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return apiService(originalRequest);
        } else {
          // If no refresh token, we can't refresh automatically
          console.error('No refresh token available');
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        // Don't redirect, just return the error
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const authenticate = async (username, password) => {
  try {
    const response = await axios.post(AUTH_URL, { username, password });
    const token = response.data.token;

    if (!token) {
      throw new Error('Invalid response from server: missing token');
    }

    localStorage.setItem('jwtToken', token);
    apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return token;
  } catch (error) {
    console.error('Authentication error details:', error.response || error);
    throw new Error(error.response?.data?.message || 'Authentication failed. Please try again.');
  }
};

export const getCurrentUserDetails = async () => {
  try {
    const response = await apiService.get(USER_DETAILS_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching current user details:', error);
    throw new Error('Failed to fetch user details');
  }
};

export const login = async (username, password) => {
  try {
    await authenticate(username, password);
    const userDetails = await getCurrentUserDetails();
    return userDetails;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const getUserDetailsById = async (userId) => {
  try {
    const response = await apiService.get(`/users/${userId}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('jwtToken');
  delete apiService.defaults.headers.common['Authorization'];
};

export const isLoggedIn = () => {
  return !!localStorage.getItem('jwtToken');
};

export const getDashboardData = async () => {
  try {
    const response = await apiService.get('/dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await apiService.get('/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
};
export const getNotifications = async () => {
  try {
    const response = await apiService.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    // Instead of throwing, return an empty array or error object
    return { error: 'Failed to fetch notifications', data: [] };
  }
};

export const deleteSocialUpdate = async (id) => {
  try {
    const response = await apiService.delete(`/social-updates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting social update:', error);
    throw error;
  }
};

export const getUnreadNotificationsCount = async () => {
  try {
    const response = await apiService.get('/notifications/unread-count');
    return response.data;
  } catch (error) {
    console.error('Error fetching unread notifications count:', error);
    throw error;
  }
};

export const getTaskById = async (id) => {
  try {
    const response = await apiService.get(`/tasks/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching task:', error);
    throw error;
  }
};

export const getNotificationDetails = async (id) => {
  try {
    const response = await apiService.get(`/notifications/${id}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching notification details:', error);
    throw error;
  }
};

export const markNotificationAsRead = async (id) => {
  try {
    const response = await apiService.post(`/notifications/mark-read/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

export const getUpcomingReminders = async () => {
  try {
    const response = await apiService.get('/reminders/upcoming');
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming reminders:', error);
    throw error;
  }
};

export const getCoordinators = async (params = {}) => {
  try {
    const response = await apiService.get('/coordinators', { params });
    return response.data.coordinators || [];
  } catch (error) {
    console.error('Error fetching coordinators:', error);
    throw error;
  }
};

export const getLeads = async (filters) => {
  try {
    const response = await apiService.get('/leads', { params: filters });
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const getLead = async (id) => {
  try {
    const response = await apiService.get(`/leads/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
  }
};

export const createLead = async (data) => {
  try {
    const response = await apiService.post('/leads', data);
    return response.data;
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
};

export const updateLead = async (id, data) => {
  try {
    const response = await apiService.put(`/leads/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating lead:', error);
    throw error;
  }
};

export const deleteLead = async (id) => {
  try {
    const response = await apiService.delete(`/leads/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
};

export const bulkAssignLeads = async (data) => {
  try {
    const response = await apiService.post('/leads/bulk-assign', data);
    return response.data;
  } catch (error) {
    console.error('Error bulk assigning leads:', error);
    throw error;
  }
};

export const getTasks = async (filters) => {
  try {
    const response = await apiService.get('/tasks', { params: filters });
    return Array.isArray(response.data) ? response.data : (response.data?.tasks ?? []);
  } catch (error) {
    console.error('Error in getTasks:', error);
    throw error;
  }
};

export const createTask = async (taskData) => {
  try {
    const response = await apiService.post('/tasks', taskData);
    return response.data;
  } catch (error) {
    console.error('Error in createTask:', error);
    throw error;
  }
};

export const updateTask = async (id, data) => {
  try {
    const response = await apiService.put(`/tasks/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};

export const deleteTask = async (id) => {
  try {
    const response = await apiService.delete(`/tasks/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};

export const getNotes = async (leadId) => {
  try {
    const response = await apiService.get(`/leads/${leadId}/notes`);
    return response.data;
  } catch (error) {
    console.error('Error in getNotes:', error);
    throw error;
  }
};

export const addNote = async (leadId, noteContent) => {
  try {
    const response = await apiService.post(`/leads/${leadId}/notes`, { note_content: noteContent });
    return response.data;
  } catch (error) {
    console.error('Error in addNote:', error);
    throw error;
  }
};

export const getCannedResponses = async (params) => {
  try {
    const response = await apiService.get('/canned-responses', { params });
    return response.data;
  } catch (error) {
    console.error('Error in getCannedResponses:', error);
    throw error;
  }
};

export const getCannedResponse = async (id) => {
  try {
    const response = await apiService.get(`/canned-responses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching canned response:', error);
    throw error;
  }
};

export const createCannedResponse = async (data) => {
  try {
    const response = await apiService.post('/canned-responses', data);
    return response.data;
  } catch (error) {
    console.error('Error creating canned response:', error);
    throw error;
  }
};

export const updateCannedResponse = async (id, data) => {
  try {
    const response = await apiService.put(`/canned-responses/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating canned response:', error);
    throw error;
  }
};

export const deleteCannedResponse = async (id) => {
  try {
    const response = await apiService.delete(`/canned-responses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting canned response:', error);
    throw error;
  }
};

export const getReminders = async () => {
  try {
    const response = await apiService.get('/reminders');
    return response.data;
  } catch (error) {
    console.error('Error fetching reminders:', error);
    throw error;
  }
};

export const getReminder = async (reminderId) => {
  try {
    const response = await apiService.get(`/reminders/${reminderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reminder:', error);
    throw error;
  }
};

export const createReminder = async (data) => {
  try {
    const response = await apiService.post('/reminders', data);
    return response.data;
  } catch (error) {
    console.error('Error creating reminder:', error);
    throw error;
  }
};

export const updateReminder = async (id, data) => {
  try {
    const response = await apiService.put(`/reminders/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating reminder:', error);
    throw error;
  }
};

export const deleteReminder = async (id) => {
  try {
    const response = await apiService.delete(`/reminders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting reminder:', error);
    throw error;
  }
};

export const getLeadStatusHistory = async (leadId) => {
  try {
    const response = await apiService.get(`/leads/${leadId}/status-history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead status history:', error);
    throw error;
  }
};

export const getUserNotes = async () => {
  try {
    const response = await apiService.get('/user-notes');
    return response.data;
  } catch (error) {
    console.error('Error fetching user notes:', error);
    throw error;
  }
};

export const createUserNote = async (data) => {
  try {
    const response = await apiService.post('/user-notes', data);
    return response.data;
  } catch (error) {
    console.error('Error creating user note:', error);
    throw error;
  }
};

export const updateUserNote = async (id, data) => {
  try {
    const response = await apiService.put(`/user-notes/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating user note:', error);
    throw error;
  }
};

export const deleteUserNote = async (id) => {
  try {
    const response = await apiService.delete(`/user-notes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user note:', error);
    throw error;
  }
};

export const getWikiEntries = async () => {
  try {
    const response = await apiService.get('/wiki');
    return response.data;
  } catch (error) {
    console.error('Error fetching wiki entries:', error);
    throw error;
  }
};

export const createWikiEntry = async (data) => {
  try {
    const response = await apiService.post('/wiki', data);
    return response.data;
  } catch (error) {
    console.error('Error creating wiki entry:', error);
    throw error;
  }
};

export const updateWikiEntry = async (id, data) => {
  try {
    const response = await apiService.put(`/wiki/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating wiki entry:', error);
    throw error;
  }
};

export const deleteWikiEntry = async (id) => {
  try {
    const response = await apiService.delete(`/wiki/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting wiki entry:', error);
    throw error;
  }
};

// Social Updates API calls
export const getSocialUpdates = async () => {
  try {
    const response = await apiService.get('/social-updates');
    const updatesWithDetails = await Promise.all(
      response.data.map(async (update) => {
        const detailsResponse = await apiService.get(`/social-updates/${update.id}`);
        return {
          ...update,
          likes_count: detailsResponse.data.likes_count,
          comments_count: detailsResponse.data.comments_count,
          is_liked: detailsResponse.data.is_liked
        };
      })
    );
    return updatesWithDetails;
  } catch (error) {
    console.error('Error fetching social updates:', error);
    throw error;
  }
};

export const uploadMedia = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await apiService.post('/media-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
    } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export const createSocialUpdate = async (data) => {
  try {
    const formData = new FormData();

    if (data.content) {
      formData.append('content', data.content);
    }

    if (data.file) {
      formData.append('file', data.file);
    }

    const response = await apiService.post('/social-updates', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating social update:', error);
    throw error;
  }
};

export const uploadMediaToWordPress = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await apiService.post('/social-updates', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data; // This should include media_id, media_url, and media_type
  } catch (error) {
    console.error('Error uploading media to WordPress:', error);
    throw error;
  }
};

export const likeSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/like`);
    return response.data;
  } catch (error) {
    console.error('Error liking social update:', error);
    throw error;
  }
};

export const unlikeSocialUpdate = async (id) => {
  try {
    const response = await apiService.delete(`/social-updates/${id}/like`);
    return response.data;
  } catch (error) {
    console.error('Error unliking social update:', error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await apiService.get('/user-profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
export const getUserDetails = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(`${API_BASE_URL}/users/me/details`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const updateUserProfile = async (userId, formData) => {
  try {
    const response = await apiService.put(`/users/${userId}/details`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const pinSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/pin`);
    return response.data;
  } catch (error) {
    console.error('Error pinning social update:', error);
    throw error;
  }
};

export const unpinSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/unpin`);
    return response.data;
  } catch (error) {
    console.error('Error unpinning social update:', error);
    throw error;
  }
};

export const getComments = async (updateId) => {
  try {
    const response = await apiService.get(`/social-updates/${updateId}/comments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};

export const addComment = async (updateId, data) => {
  try {
    const response = await apiService.post(`/social-updates/${updateId}/comments`, data);
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const getGlobalChatMessages = async () => {
  try {
    const response = await apiService.get('/global-chat');
    console.log('Raw API response:', response); // Log the entire response
    if (response.data && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error('Unexpected response structure from server');
    }
  } catch (error) {
    console.error('Error fetching global chat messages:', error);
    throw error;
  }
};

export const sendGlobalChatMessage = async (message, image = null) => {
  try {
    let data;
    let headers;

    if (image) {
      // Message with image
      const formData = new FormData();
      formData.append('message', message);
      formData.append('image', image, image.name); // Ensure filename is included
      data = formData;
      headers = { 'Content-Type': 'multipart/form-data' };
      console.log('Sending message with image:', { message, imageName: image.name });
    } else {
      // Text-only message
      data = { message };
      headers = { 'Content-Type': 'application/json' };
      console.log('Sending text-only message:', message);
    }

    const response = await apiService.post('/global-chat', data, { headers });
    console.log('Send message response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending global chat message:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};


export const markGlobalChatMessagesAsRead = async () => {
  try {
    const response = await apiService.post('/global-chat/mark-read');
    return response.data;
  } catch (error) {
    console.error('Error marking global chat messages as read:', error);
    throw error;
  }
};

export default apiService;