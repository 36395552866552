import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Select, Button, message, Spin, Modal, Form, Input, DatePicker, Avatar, Tag, Divider, Typography } from 'antd';
import { PlusOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { updateReminder, deleteReminder, createReminder, getAllUsers, getLead } from '../apiService';
import moment from 'moment';
import axios from 'axios';

import LeadDetailsModal from './LeadDetailsModal';

const { Option } = Select;
const { Text } = Typography;

const Reminders = ({ currentUser }) => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: 'pending',
    assigned_to: currentUser.id
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [leadModalVisible, setLeadModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  });

  const fetchReminders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`https://niledutech.in/wp-json/ei-crm/v1/reminders`, {
        params: { 
          ...filters, 
          page: pagination.current, 
          per_page: pagination.pageSize,
          assigned_to: currentUser.id // Ensure this is included in the request
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      
      if (response.data && Array.isArray(response.data.reminders)) {
        const formattedReminders = await Promise.all(response.data.reminders.map(async reminder => {
          return {
            ...reminder,
            formattedDate: moment.utc(reminder.datetime).format('D-MMM-YYYY'),
            formattedTime: moment.utc(reminder.datetime).format('h:mm A'),
            created_at: moment(reminder.created_at).format('D-MMM-YYYY h:mm A'),
            assigned_to_name: reminder.assigned_to_name || 'Unknown User',
          };
        }));
        setReminders(formattedReminders);
        setPagination(prev => ({
          ...prev,
          total: response.data.total,
          current: response.data.page,
          pageSize: response.data.per_page
        }));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching reminders:', error);
      setError('Failed to load reminders. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters, pagination.current, pagination.pageSize, currentUser.id]);

  useEffect(() => {
    fetchReminders();
    fetchUsers();
  }, [fetchReminders]);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const handleUpdateReminder = async (id, status) => {
    try {
      await updateReminder(id, { status });
      message.success('Reminder updated successfully');
      fetchReminders();
    } catch (error) {
      console.error('Error updating reminder:', error);
      message.error('Failed to update reminder. Please try again.');
    }
  };

  const handleDeleteReminder = async (id) => {
    try {
      await deleteReminder(id);
      message.success('Reminder deleted successfully');
      fetchReminders();
    } catch (error) {
      console.error('Error deleting reminder:', error);
      message.error('Failed to delete reminder. Please try again.');
    }
  };

  const handleFilterChange = (value) => {
    setFilters(prev => ({ ...prev, status: value }));
    setPagination(prev => ({ ...prev, current: 1 }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleCreate = async (values) => {
    try {
      const reminderData = {
        ...values,
        datetime: values.datetime.format('YYYY-MM-DDTHH:mm:ss+00:00'),
        user_id: currentUser.id
      };
      await createReminder(reminderData);
      message.success('Reminder created successfully');
      setIsModalVisible(false);
      form.resetFields();
      fetchReminders();
    } catch (error) {
      console.error('Error creating reminder:', error);
      message.error('Failed to create reminder. Please try again.');
    }
  };

  const handleUpdate = async (values) => {
    try {
      const reminderData = {
        ...values,
        datetime: values.datetime.format('YYYY-MM-DDTHH:mm:ss+00:00'),
      };
      await updateReminder(selectedReminder.id, reminderData);
      message.success('Reminder updated successfully');
      setIsUpdateModalVisible(false);
      updateForm.resetFields();
      fetchReminders();
    } catch (error) {
      console.error('Error updating reminder:', error);
      message.error('Failed to update reminder. Please try again.');
    }
  };

  const groupRemindersByDate = (remindersList) => {
    const grouped = remindersList.reduce((acc, reminder) => {
      const date = reminder.formattedDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(reminder);
      return acc;
    }, {});

    return Object.entries(grouped).sort(([dateA], [dateB]) => 
      moment(dateA, 'D-MMM-YYYY').diff(moment(dateB, 'D-MMM-YYYY'))
    );
  };

  const handleReminderClick = async (reminder) => {
    setSelectedReminder(reminder);
    updateForm.setFieldsValue({
      ...reminder,
      datetime: moment.utc(reminder.datetime),
    });
    setIsUpdateModalVisible(true);
  };

  const handleViewLeadDetails = async () => {
    if (selectedReminder && selectedReminder.lead_id) {
      try {
        const leadData = await getLead(selectedReminder.lead_id);
        setSelectedLead(leadData);
        setLeadModalVisible(true);
      } catch (error) {
        console.error('Error fetching lead details:', error);
        message.error('Failed to load lead details. Please try again.');
      }
    } else {
      message.warning('No lead associated with this reminder.');
    }
  };

  const renderReminderCard = (reminder) => (
    <Card 
      key={reminder.id} 
      size="small" 
      title={`Reminder for ${reminder.type.replace('next_call_date', 'Next Call Date').replace('booking_date', 'Booking Date').replace('meeting', 'Meeting')}`}
      onClick={() => handleReminderClick(reminder)}
      style={{ cursor: 'pointer', position: 'relative' }}
      extra={
        <CloseOutlined 
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteReminder(reminder.id);
          }}
          style={{ color: 'red', fontSize: '16px' }}
        />
      }
    >
      <p><strong>Lead ID:</strong> {reminder.lead_id || 'N/A'}</p>
      <p><strong>Due Date and Time:</strong> {`${reminder.formattedDate} ${reminder.formattedTime}`}</p>
      <div>
        <Text strong>Content:</Text>
        <div style={{ 
          backgroundColor: '#e6f7ff', 
          borderRadius: '8px', 
          padding: '10px', 
          marginTop: '5px',
          border: '1px solid #91d5ff'
        }}>
          <Text italic style={{ fontSize: '16px' }}>{reminder.message}</Text>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <strong>Assigned to:</strong>
        <Avatar src={reminder.assigned_to_avatar} style={{ marginLeft: '10px', marginRight: '5px' }} />
        {reminder.assigned_to_name || 'N/A'}
      </div>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tag color={reminder.status === 'pending' ? 'red' : 'green'}>
          {reminder.status.toUpperCase()}
        </Tag>
        <Divider type="vertical" style={{ height: '30px' }} />
        {reminder.status === 'pending' && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateReminder(reminder.id, 'completed');
            }}
            type="primary"
          >
            Mark Complete
          </Button>
        )}
      </div>
    </Card>
  );

  const groupedReminders = groupRemindersByDate(reminders);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Reminders</h1>
        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
          Create Reminder
        </Button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      <Card 
        title="Reminders List" 
        extra={
          <div className="flex space-x-4">
            <Select
              style={{ width: 120 }}
              value={filters.status}
              onChange={handleFilterChange}
            >
              <Option value="pending">Pending</Option>
              <Option value="completed">Completed</Option>
            </Select>
            <Button onClick={fetchReminders}>Refresh</Button>
          </div>
        }
      >
        {loading ? (
          <div className="text-center py-4">
            <Spin size="large" />
          </div>
        ) : reminders.length === 0 ? (
          <div className="text-center py-4">No reminders found.</div>
        ) : (
          groupedReminders.map(([date, dateReminders]) => (
            <Card key={date} title={date} className="mb-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {dateReminders.map(renderReminderCard)}
              </div>
            </Card>
          ))
        )}
      </Card>

      <Modal
        title="Create New Reminder"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreate} layout="vertical">
          <Form.Item
            name="lead_id"
            label="Lead ID (Optional)"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select the reminder type!' }]}
          >
            <Select>
              <Option value="booking_date">Booking Date</Option>
              <Option value="next_call_date">Next Call Date</Option>
              <Option value="meeting">Meeting</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="datetime"
            label="Date and Time"
            rules={[{ required: true, message: 'Please select the date and time!' }]}
          >
            <DatePicker showTime format="D-MMM-YYYY h:mm A" />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: 'Please input the reminder message!' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="assigned_to"
            label="Assign To"
            rules={[{ required: true, message: 'Please select a user to assign the reminder!' }]}
          >
            <Select>
              {users.map(user => (
                <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Reminder
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Reminder"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdate} layout="vertical">
          <Form.Item
            name="lead_id"
            label="Lead ID (Optional)"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select the reminder type!' }]}
          >
            <Select>
              <Option value="booking_date">Booking Date</Option>
              <Option value="next_call_date">Next Call Date</Option>
              <Option value="meeting">Meeting</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="datetime"
            label="Date and Time"
            rules={[{ required: true, message: 'Please select the date and time!' }]}
          >
            <DatePicker showTime format="D-MMM-YYYY h:mm A" />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: 'Please input the reminder message!' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="assigned_to"
            label="Assign To"
            rules={[{ required: true, message: 'Please select a user to assign the reminder!' }]}
          >
            <Select>
            {users.map(user => (
                <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please select the reminder status!' }]}
          >
            <Select>
              <Option value="pending">Pending</Option>
              <Option value="completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Reminder
            </Button>
            <Button 
              type="default" 
              onClick={handleViewLeadDetails} 
              style={{ marginLeft: '10px' }}
              icon={<UserOutlined />}
            >
              View Lead Details
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <LeadDetailsModal
        visible={leadModalVisible}
        onClose={() => setLeadModalVisible(false)}
        lead={selectedLead}
      />
    </div>
  );
};

Reminders.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display_name: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default Reminders;