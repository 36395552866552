import moment from 'moment';

// Parse date from API response (e.g., "31-Aug-2024")
export const parseDateFromAPI = (date) => {
  if (!date) return null;
  return moment(date, 'DD-MMM-YYYY').toDate();
};

// Parse time from API response (e.g., "06:00 AM")
export const parseTimeFromAPI = (time) => {
  if (!time) return null;
  return moment(time, 'hh:mm A').toDate();
};

// Parse date and time from API response (e.g., "30-Aug-2024 12:00 AM")
export const parseDateTimeFromAPI = (dateTime) => {
  if (!dateTime) return null;
  return moment(dateTime, 'DD-MMM-YYYY hh:mm A').toDate();
};

// Format date for API requests (e.g., "2024-08-31")
export const formatDateForAPI = (date) => {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
};

// Format time for API requests (e.g., "06:00:00")
export const formatTimeForAPI = (time) => {
    return moment(time).format('HH:mm:ss');
  };

// Format date and time for API requests (e.g., "2024-08-30 00:00:00")
export const formatDateTimeForAPI = (dateTime) => {
  if (!dateTime) return null;
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
};

// Format date for display (e.g., "31-August-2024")
export const formatDateForDisplay = (date) => {
  if (!date) return '';
  return moment(date).format('D-MMMM-YYYY');
};

// Format time for display (e.g., "06:00 AM")
export const formatTimeForDisplay = (time) => {
    return moment(time, 'HH:mm:ss').format('hh:mm A');
  };

// Format date and time for display (e.g., "30-August-2024 12:00 AM")
export const formatDateTimeForDisplay = (dateTime) => {
  if (!dateTime) return '';
  return moment(dateTime).format('D-MMMM-YYYY hh:mm A');
};

// Format date and time for form inputs
export const formatDateTimeForForm = (dateTime) => {
  if (!dateTime) return null;
  return moment(dateTime);
};

// Get current date in API format
export const getCurrentDateForAPI = () => {
  return moment().format('YYYY-MM-DD');
};

// Get current time in API format
export const getCurrentTimeForAPI = () => {
  return moment().format('HH:mm:ss');
};

// Get current date and time in API format
export const getCurrentDateTimeForAPI = () => {
  return moment().format('YYYY-MM-DD HH:mm:ss');
};

// Check if a date is valid
export const isValidDate = (date) => {
  return moment(date, 'YYYY-MM-DD', true).isValid();
};

// Check if a time is valid
export const isValidTime = (time) => {
  return moment(time, 'HH:mm:ss', true).isValid();
};

// Check if a date and time combination is valid
export const isValidDateTime = (dateTime) => {
  return moment(dateTime, 'YYYY-MM-DD HH:mm:ss', true).isValid();
};

// Calculate the difference between two dates in days
export const getDaysBetweenDates = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

// Add days to a date
export const addDaysToDate = (date, days) => {
  return moment(date).add(days, 'days').format('YYYY-MM-DD');
};

// Subtract days from a date
export const subtractDaysFromDate = (date, days) => {
  return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
};

// Convert 12-hour time to 24-hour time
export const convertTo24HourFormat = (time) => {
  return moment(time, 'hh:mm A').format('HH:mm');
};

// Convert 24-hour time to 12-hour time
export const convertTo12HourFormat = (time) => {
  return moment(time, 'HH:mm').format('hh:mm A');
};