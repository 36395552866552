import moment from 'moment';

export const formatDateForAPI = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const parseRevenue = (revenueString) => {
  if (!revenueString) return 0;
  return parseFloat(revenueString.replace('₹', '').replace(/,/g, '')) || 0;
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};