import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Button, Input, Select, message } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { getLeadStatusHistory, getCannedResponses } from '../apiService';
import { formatDateTimeForDisplay } from '../utils/dateUtils';

const { TextArea } = Input;
const { Option } = Select;

const NotesPopover = ({ leadId, phone, addNote, notes, refreshNotes }) => {
  const [statusHistory, setStatusHistory] = useState([]);
  const [cannedResponses, setCannedResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState('');
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    fetchStatusHistory();
    fetchCannedResponses();
  }, [leadId]);

  const fetchStatusHistory = async () => {
    try {
      const historyResponse = await getLeadStatusHistory(leadId);
      setStatusHistory(Array.isArray(historyResponse) ? historyResponse : []);
    } catch (error) {
      console.error('Error fetching status history:', error);
      message.error('Failed to load status history');
    }
  };

  const fetchCannedResponses = async () => {
    try {
      const responses = await getCannedResponses();
      setCannedResponses(Array.isArray(responses) ? responses : []);
    } catch (error) {
      console.error('Error fetching canned responses:', error);
      message.error('Failed to load canned responses');
    }
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim()) {
      message.error('Note content cannot be empty');
      return;
    }
    try {
      // Check if addNote expects one or two parameters
      if (addNote.length === 2) {
        await addNote(leadId, newNote);
      } else {
        await addNote(newNote);
      }
      setNewNote('');
      refreshNotes();
      message.success('Note added successfully');
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(selectedResponse);
    window.open(`https://web.whatsapp.com/send?phone=${phone}&text=${message}`, '_blank');
  };

  const combinedData = [
    ...notes.map(note => ({ ...note, type: 'note' })),
    ...statusHistory.map(status => ({ ...status, type: 'status' }))
  ].sort((a, b) => new Date(b.created_at || b.changed_at) - new Date(a.created_at || a.changed_at));

  return (
    <div style={{ width: 300 }}>
      <div style={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '10px' }}>
        <List
          dataSource={combinedData}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={`${item.type === 'note' ? (item.created_by_name || 'Unknown') : (item.changed_by || 'Unknown')} - ${formatDateTimeForDisplay(item.created_at || item.changed_at)}`}
                description={
                  item.type === 'note' 
                    ? item.note_content
                    : `Status changed from ${item.old_status || 'Unknown'} to ${item.new_status || 'Unknown'}`
                }
              />
            </List.Item>
          )}
        />
      </div>
      <form onSubmit={handleAddNote}>
        <TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          rows={4}
          placeholder="Enter new note"
        />
        <Button type="primary" htmlType="submit" style={{ marginTop: '10px' }}>Add Note</Button>
      </form>
      <Select
        style={{ width: '100%', marginTop: '10px' }}
        placeholder="Select canned response"
        onChange={setSelectedResponse}
      >
        {cannedResponses.map(response => (
          <Option key={response.id} value={response.content}>{response.title}</Option>
        ))}
      </Select>
      <Button 
        type="primary" 
        icon={<WhatsAppOutlined />} 
        onClick={handleWhatsAppClick}
        style={{ marginTop: '10px' }}
        disabled={!selectedResponse}
      >
        Send WhatsApp
      </Button>
    </div>
  );
};

NotesPopover.propTypes = {
  leadId: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired,
  addNote: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  refreshNotes: PropTypes.func.isRequired,
};

export default NotesPopover;