import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, List, Modal, Button, Form, Input, Select, DatePicker, message } from 'antd';
import { BellOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { getNotifications, markNotificationAsRead, updateTask, getTaskById, updateReminder, getReminder, getAllUsers } from '../apiService';
import { format, isValid, parseISO } from 'date-fns';
import moment from 'moment-timezone';
import './Notifications.css';

const { Option } = Select;
const { TextArea } = Input;

const NotificationOverlay = ({ children }) => (
  <div style={{
    background: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    padding: '8px 0',
    minWidth: '350px',
    maxWidth: '450px',
    width: '100%',
  }}>
    {children}
  </div>
);

NotificationOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

const Notifications = ({ fullyAuthenticated }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [form] = Form.useForm();
  const [taskForm] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [leadAssignmentSummary, setLeadAssignmentSummary] = useState(null);
  const [leadStatusSummary, setLeadStatusSummary] = useState(null);

  const fetchNotifications = useCallback(async () => {
    if (!fullyAuthenticated) return;

    try {
      const response = await getNotifications();
      const groupedNotifications = groupNotifications(response);
      setNotifications(groupedNotifications);
      const unreadNotifications = groupedNotifications.filter(notification => notification.is_read === "0");
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      message.error('Failed to load notifications');
    }
  }, [fullyAuthenticated]);

  useEffect(() => {
    if (fullyAuthenticated) {
      fetchNotifications();
      fetchUsers();
      const interval = setInterval(fetchNotifications, 60000); // Refresh every minute
      return () => clearInterval(interval);
    }
  }, [fetchNotifications, fullyAuthenticated]);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const groupNotifications = (notificationsArray) => {
    const grouped = notificationsArray.reduce((acc, notification) => {
      if (notification.type === 'lead_reassigned') {
        if (!acc.leadAssignments) {
          acc.leadAssignments = { count: 0, notifications: [], type: 'lead_assignments_summary', is_read: "0" };
        }
        acc.leadAssignments.count++;
        acc.leadAssignments.notifications.push(notification);
      } else if (notification.type === 'lead_status_changed') {
        if (!acc.leadStatusUpdates) {
          acc.leadStatusUpdates = { count: 0, notifications: [], type: 'lead_status_summary', is_read: "0" };
        }
        acc.leadStatusUpdates.count++;
        acc.leadStatusUpdates.notifications.push(notification);
      } else {
        acc.other.push(notification);
      }
      return acc;
    }, { other: [] });

    if (grouped.leadAssignments) {
      setLeadAssignmentSummary(grouped.leadAssignments);
    } else {
      setLeadAssignmentSummary(null);
    }

    if (grouped.leadStatusUpdates) {
      setLeadStatusSummary(grouped.leadStatusUpdates);
    } else {
      setLeadStatusSummary(null);
    }

    return [
      ...grouped.other,
      ...(grouped.leadAssignments ? [grouped.leadAssignments] : []),
      ...(grouped.leadStatusUpdates ? [grouped.leadStatusUpdates] : [])
    ];
  };

  const handleNotificationClick = async (notification) => {
    try {
      if (!notification) {
        console.error('Invalid notification object:', notification);
        message.error('Invalid notification data');
        return;
      }

      if (notification.notifications) {
        // This is a grouped notification
        if (notification.type === 'lead_assignments_summary') {
          await Promise.all(notification.notifications.map(n => markNotificationAsRead(n.id)));
          setLeadAssignmentSummary(null);
          message.success(`Marked ${notification.count} lead assignment notifications as read`);
        } else if (notification.type === 'lead_status_summary') {
          await Promise.all(notification.notifications.map(n => markNotificationAsRead(n.id)));
          setLeadStatusSummary(null);
          message.success(`Marked ${notification.count} lead status update notifications as read`);
        }
      } else {
        // This is an individual notification
        if (notification.type === 'reminder') {
          const reminderDetails = await getReminder(notification.reference_id);
          setSelectedReminder(reminderDetails);
          setReminderModalVisible(true);
        } else if (notification.type.includes('task')) {
          const taskDetails = await getTaskById(notification.reference_id);
          setSelectedTask(taskDetails);
          taskForm.setFieldsValue({
            ...taskDetails,
            due_date: taskDetails.due_date ? moment(taskDetails.due_date) : null,
          });
          setTaskModalVisible(true);
        } else {
          await markNotificationAsRead(notification.id);
        }
      }
      
      setNotifications(prevNotifications =>
        prevNotifications.map(n =>
          n.id === notification.id ? { ...n, is_read: "1" } : n
        )
      );
      setUnreadCount(prevCount => Math.max(0, prevCount - 1));
    } catch (error) {
      console.error('Error handling notification click:', error);
      message.error('Failed to process notification');
    }
  };

  const handleReminderUpdate = async (values) => {
    try {
      const reminderData = {
        ...values,
        datetime: moment.tz(values.datetime, 'Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss+00:00')
      };
      await updateReminder(selectedReminder.id, reminderData);
      message.success('Reminder updated successfully');
      setReminderModalVisible(false);
      fetchNotifications();
    } catch (error) {
      console.error('Error updating reminder:', error);
      message.error('Failed to update reminder');
    }
  };

  const handleTaskUpdate = async (values) => {
    try {
      const taskData = {
        ...values,
        due_date: values.due_date ? values.due_date.format('YYYY-MM-DDTHH:mm:ss+00:00') : null,
      };
      await updateTask(selectedTask.id, taskData);
      message.success('Task updated successfully');
      setTaskModalVisible(false);
      fetchNotifications();
    } catch (error) {
      console.error('Error updating task:', error);
      message.error('Failed to update task');
    }
  };

  const safeFormatDate = (dateString) => {
    if (!dateString) return 'Unknown date';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMM d, yyyy h:mm a') : 'Invalid date';
  };

  const renderNotificationMessage = (notification) => {
    if (notification.notifications) {
      // This is a grouped notification
      return `${notification.count} ${notification.type === 'lead_assignments_summary' ? 'new leads assigned' : 'lead status updates'}`;
    } else {
      // This is an individual notification
      return notification.message || 'New Notification';
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'reminder':
      case 'task_due':
      case 'task_assigned':
      case 'task_status_update':
        return <ClockCircleOutlined style={{ color: '#faad14' }} />;
      case 'lead_assignments_summary':
      case 'lead_status_summary':
      case 'lead_reassigned':
      case 'lead_status_changed':
        return <UserOutlined style={{ color: '#1890ff' }} />;
      default:
        return <BellOutlined style={{ color: '#8c8c8c' }} />;
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const unreadNotifications = notifications.filter(n => n.is_read === "0");
      await Promise.all(unreadNotifications.map(n => 
        n.notifications 
          ? Promise.all(n.notifications.map(subN => markNotificationAsRead(subN.id)))
          : markNotificationAsRead(n.id)
      ));
      setNotifications(prevNotifications =>
        prevNotifications.map(n => ({ ...n, is_read: "1" }))
      );
      setUnreadCount(0);
      setLeadAssignmentSummary(null);
      setLeadStatusSummary(null);
      message.success('All notifications marked as read');
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      message.error('Failed to mark all notifications as read');
    }
  };

  const notificationList = (
    <NotificationOverlay>
      <div style={{ padding: '8px 16px', borderBottom: '1px solid #f0f0f0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Notifications</span>
        <Button size="small" onClick={handleMarkAllAsRead}>Mark all as read</Button>
      </div>
      <List
        className="notification-list"
        itemLayout="vertical"
        dataSource={[...notifications, leadAssignmentSummary, leadStatusSummary].filter(Boolean)}
        locale={{ emptyText: <div className="empty-notification">No notifications</div> }}
        renderItem={(item) => (
          <List.Item className="notification-item" onClick={() => handleNotificationClick(item)}>
            <div className="notification-content">
              <div className="notification-icon">{getNotificationIcon(item.type)}</div>
              <div className="notification-text">
                <div className="notification-title">{renderNotificationMessage(item)}</div>
                <div className="notification-time">{safeFormatDate(item.created_at)}</div>
              </div>
            </div>
          </List.Item>
        )}
      />
    </NotificationOverlay>
  );

  return (
    <>
      <Dropdown 
        overlay={notificationList}
        trigger={['click']} 
        placement="bottomRight"
      >
        <Badge count={unreadCount} className="notification-badge">
          <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
        </Badge>
      </Dropdown>
      <Modal
        title="Reminder Details"
        open={reminderModalVisible}
        onCancel={() => setReminderModalVisible(false)}
        footer={null}
      >
        {selectedReminder && (
          <Form form={form} onFinish={handleReminderUpdate} layout="vertical">
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
              <Select>
                <Option value="booking_date">Booking Date</Option>
                <Option value="next_call_date">Next Call Date</Option>
                <Option value="meeting">Meeting</Option>
              </Select>
            </Form.Item>
            <Form.Item name="datetime" label="Date and Time" rules={[{ required: true }]}>
              <DatePicker showTime format="D-MMM-YYYY h:mm A" />
            </Form.Item>
            <Form.Item name="message" label="Message" rules={[{ required: true }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name="status" label="Status" rules={[{ required: true }]}>
              <Select>
                <Option value="pending">Pending</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Update Reminder</Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title="Task Details"
        open={taskModalVisible}
        onCancel={() => setTaskModalVisible(false)}
        footer={null}
      >
        {selectedTask && (
          <Form form={taskForm} onFinish={handleTaskUpdate} layout="vertical">
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name="due_date" label="Due Date" rules={[{ required: true }]}>
              <DatePicker showTime format="D-MMM-YYYY h:mm A" />
            </Form.Item>
            <Form.Item name="assigned_to" label="Assigned To" rules={[{ required: true }]}>
              <Select>
                {users.map(user => (
                  <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="status" label="Status" rules={[{ required: true }]}>
              <Select>
                <Option value="pending">Pending</Option>
                <Option value="in_progress">In Progress</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Update Task</Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

Notifications.propTypes = {
  fullyAuthenticated: PropTypes.bool.isRequired,
};

export default Notifications;