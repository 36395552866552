import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Select, DatePicker, TimePicker, Popover, message, Button, Tag } from 'antd';
import { getLeads, updateLead, addNote, bulkAssignLeads, getAllUsers, getNotes, getLeadStatusHistory, getCoordinators } from '../apiService';
import moment from 'moment';
import NotesPopover from './NotesPopover';
import { 
  formatDateForAPI, 
  formatTimeForAPI, 
  formatDateTimeForAPI,
  formatDateForDisplay,
  formatDateTimeForDisplay
} from '../utils/dateUtils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const LeadsManagementPage = ({ currentUser }) => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    search: '',
    status: '',
    date_from: null,
    date_to: null,
    coordinator_id: currentUser && currentUser.role === 'crm_executive' ? currentUser.id : '',
    booking_date: null,
    booking_time: null,
    priority: '',
  });
  const [totalPages, setTotalPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [bulkAssignUser, setBulkAssignUser] = useState('');
  const [coordinators, setCoordinators] = useState([]);

  const isAdmin = currentUser.role === 'administrator';

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getLeads(filters);
      const leadsWithDetails = await Promise.all(response.leads.map(async (lead, index) => {
        const notes = await getNotes(lead.id);
        const statusHistory = await getLeadStatusHistory(lead.id);
        const lastNote = notes[0] || null;
        const lastStatusChange = statusHistory[statusHistory.length - 1] || null;
        const lastUpdateTime = Math.max(
          lastNote ? moment(lastNote.created_at).valueOf() : 0,
          lastStatusChange ? moment(lastStatusChange.changed_at).valueOf() : 0
        );
        const now = moment().valueOf();
        const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);

        return {
          ...lead,
          number: index + 1 + (filters.page - 1) * filters.per_page,
          notes,
          statusHistory,
          booking_date: lead.booking_date ? formatDateForDisplay(lead.booking_date) : null,
          booking_time: lead.booking_time ? moment(lead.booking_time, 'HH:mm:ss').format('hh:mm A') : null,
          call_date: lead.call_date ? formatDateTimeForDisplay(lead.call_date) : null,
          next_call_date: lead.next_call_date ? formatDateTimeForDisplay(lead.next_call_date) : null,
          recentlyUpdated: hoursSinceLastUpdate <= 3,
          notUpdated: hoursSinceLastUpdate > 72, // 3 days
        };
      }));
      setLeads(leadsWithDetails);
      setTotalPages(response.pages);
    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to load leads. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const fetchUsers = async () => {
    if (!isAdmin) return;
    try {
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        setUsers(response.users);
      } else {
        console.error('Unexpected user data format:', response);
        message.error('Failed to load users: Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const fetchCoordinators = async () => {
    if (!isAdmin) return;
    try {
      const response = await getCoordinators();
      setCoordinators(response);
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      message.error('Failed to load coordinators');
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchUsers();
    fetchCoordinators();
  }, [fetchLeads]);

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value, page: 1 }));
  };

  const handleCellEdit = async (key, dataIndex, value) => {
    try {
      const leadToUpdate = leads.find(lead => lead.id === key);
      if (!leadToUpdate) {
        throw new Error('Lead not found');
      }

      let updatedValue = value;
      if (dataIndex === 'booking_date') {
        updatedValue = value ? formatDateForAPI(value.toDate()) : null;
      } else if (dataIndex === 'booking_time') {
        updatedValue = value ? formatTimeForAPI(value.toDate()) : null;
      } else if (['call_date', 'next_call_date'].includes(dataIndex)) {
        updatedValue = value ? formatDateTimeForAPI(value.toDate()) : null;
      }

      const updatedLead = {
        ...leadToUpdate,
        [dataIndex]: updatedValue
      };

      const payload = Object.entries(updatedLead).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});

      await updateLead(key, payload);

      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === key 
          ? { 
              ...lead, 
              [dataIndex]: dataIndex === 'booking_date' ? formatDateForDisplay(updatedValue)
                         : dataIndex === 'booking_time' ? (updatedValue ? moment(updatedValue, 'HH:mm:ss').format('hh:mm A') : null)
                         : ['call_date', 'next_call_date'].includes(dataIndex) ? formatDateTimeForDisplay(updatedValue)
                         : updatedValue,
              recentlyUpdated: true,
              notUpdated: false
            } 
          : lead
      ));

      message.success('Lead updated successfully');
    } catch (error) {
      console.error('Error updating lead:', error);
      message.error('Failed to update lead');
    }
  };

  const handleAddNote = async (leadId, noteContent) => {
    try {
      await addNote(leadId, noteContent);
      message.success('Note added successfully');
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === leadId 
          ? { 
              ...lead, 
              notes: [{ note_content: noteContent, created_at: new Date().toISOString() }, ...lead.notes],
              recentlyUpdated: true, 
              notUpdated: false 
            }
          : lead
      ));
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleBulkAssign = async () => {
    if (selectedLeads.length === 0 || !bulkAssignUser) {
      message.error('Please select leads and a user to assign');
      return;
    }

    try {
      const data = { 
        lead_ids: selectedLeads, 
        executive_id: bulkAssignUser
      };
      await bulkAssignLeads(data);
      message.success('Leads assigned successfully');
      fetchLeads();
      setSelectedLeads([]);
      setBulkAssignUser('');
    } catch (error) {
      console.error('Error bulk assigning leads:', error);
      message.error('Failed to assign leads: ' + (error.response?.data?.message || error.message));
    }
  };

  const getStatusTag = (status) => {
    const statusStyles = {
      'Unconfirmed': { color: 'black', background: '#ffcccb' },
      'Demo Booked': { color: 'black', background: '#90EE90' },
      'Demo Done': { color: 'white', background: '#006400' },
      'Demo Missed': { color: 'white', background: '#FFA500' },
      'Follow Up': { color: 'black', background: '#FFFF00' },
      'Details Shared': { color: 'black', background: '#ADD8E6' },
      'Offer Given': { color: 'black', background: '#FFC0CB' },
      'NP1': { color: 'white', background: '#00008B' },
      'NP2': { color: 'white', background: '#00008B' },
      'NP3': { color: 'white', background: '#00008B' },
      'Closed': { color: 'white', background: '#8B0000' },
      'Future Follow': { color: 'white', background: '#008B8B' },
      'Admission': { color: 'white', background: '#006400' }
    };

    const style = statusStyles[status] || { color: 'black', background: 'white' };
    return <Tag color={style.background} style={{ color: style.color }}>{status}</Tag>;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 150,
      render: (text, record) => (
        <Input
          defaultValue={text}
          onPressEnter={(e) => handleCellEdit(record.id, 'name', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'name', e.target.value)}
        />
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      fixed: 'left',
      width: 150,
      render: (text, record) => (
        <Input
          defaultValue={text}
          onPressEnter={(e) => handleCellEdit(record.id, 'phone', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'phone', e.target.value)}
        />
      ),
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
      width: 300,
      render: (text, record) => (
        <Input
          defaultValue={text}
          onPressEnter={(e) => handleCellEdit(record.id, 'course', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'course', e.target.value)}
        />
      ),
    },
    {
      title: 'Booking Date',
      dataIndex: 'booking_date',
      key: 'booking_date',
      width: 172,
      render: (text, record) => (
        <DatePicker
          value={text ? moment(text, 'D-MMM-YYYY') : null}
          format="D-MMM-YYYY"
          onChange={(date) => handleCellEdit(record.id, 'booking_date', date)}
          allowClear
        />
      ),
    },
    {
      title: 'Booking Time',
      dataIndex: 'booking_time',
      key: 'booking_time',
      width: 150,
      render: (text, record) => (
        <TimePicker
          value={text ? moment(text, 'hh:mm A') : null}
          format="hh:mm A"
          onChange={(time) => handleCellEdit(record.id, 'booking_time', time)}
          allowClear
        />
      ),
    },
    {
      title: 'Call Date',
      dataIndex: 'call_date',
      key: 'call_date',
      width: 230,
      render: (text, record) => (
        <DatePicker
          value={text ? moment(text, 'D-MMM-YYYY hh:mm A') : null}
          format="D-MMM-YYYY hh:mm A"
          showTime={{ format: 'hh:mm A' }}
          onChange={(date) => handleCellEdit(record.id, 'call_date', date)}
          allowClear
        />
      ),
    },
    {
      title: 'Next Call Date',
      dataIndex: 'next_call_date',
      key: 'next_call_date',
      width: 230,
      render: (text, record) => (
        <DatePicker
          value={text ? moment(text, 'D-MMM-YYYY hh:mm A') : null}
          format="D-MMM-YYYY hh:mm A"
          showTime={{ format: 'hh:mm A' }}
          onChange={(date) => handleCellEdit(record.id, 'next_call_date', date)}
          allowClear
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, record) => (
        <Select
          value={text || undefined}
          style={{ width: 150 }}
          onChange={(value) => handleCellEdit(record.id, 'status', value)}
          allowClear
        >
          {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 'Follow Up', 'Details Shared', 'Offer Given', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].map(status => (
            <Option key={status} value={status}>{getStatusTag(status)}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Coordinator',
      dataIndex: 'coordinator_id',
      key: 'coordinator_name',
      width: 150,
      render: (text, record) => {
        if (!isAdmin) {
          return <span>{currentUser.display_name}</span>;
        }
        return (
          <Select
            value={text}
            style={{ width: 150 }}
            onChange={(value) => handleCellEdit(record.id, 'coordinator_id', value)}
            allowClear
          >
            <Option value={null}>Unassigned</Option>
            {users.map(user => (
              <Option key={user.id} value={user.id}>{user.display_name}</Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 100,
      render: (text, record) => (
        <Select
        value={text || undefined}
        style={{ width: 100 }}
        onChange={(value) => handleCellEdit(record.id, 'priority', value)}
        allowClear
      >
        {['Low', 'Medium', 'High'].map(priority => (
          <Option key={priority} value={priority}>{priority}</Option>
        ))}
      </Select>
    ),
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    width: 150,
    render: (text, record) => (
      <Input
        defaultValue={text ? text.replace('₹', '') : ''}
        prefix="₹"
        onPressEnter={(e) => handleCellEdit(record.id, 'revenue', e.target.value)}
        onBlur={(e) => handleCellEdit(record.id, 'revenue', e.target.value)}
      />
    ),
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 200,
    render: (notes, record) => {
      const lastNote = notes.length > 0 ? notes[0].note_content : 'No notes';
      return (
        <Popover
          content={
            <NotesPopover 
              leadId={Number(record.id)}
              phone={record.phone} 
              addNote={handleAddNote}
              notes={notes}
              refreshNotes={() => fetchLeads()}
            />
          }
          title="Notes and Logs"
          trigger="click"
        >
          <div style={{ cursor: 'pointer' }}>{lastNote}</div>
        </Popover>
      );
    },
  },
];

return (
  <div className="w-full px-4 py-8">
    <h1 className="text-3xl font-bold mb-8">Leads Management</h1>
    {error && (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
        <strong className="font-bold">Error:</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    )}
    <div className="mb-6 flex flex-wrap gap-4">
      <Input
        placeholder="Search leads"
        value={filters.search}
        onChange={(e) => handleFilterChange('search', e.target.value)}
        style={{ width: 200 }}
      />
      <Select
        style={{ width: 150 }}
        placeholder="Status"
        value={filters.status}
        onChange={(value) => handleFilterChange('status', value)}
      >
        <Option value="">All</Option>
        {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 'Follow Up', 'Details Shared', 'Offer Given', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].map(status => (
          <Option key={status} value={status}>{status}</Option>
        ))}
      </Select>
      <RangePicker
        style={{ width: 300 }}
        value={[filters.date_from ? moment(filters.date_from) : null, filters.date_to ? moment(filters.date_to) : null]}
        onChange={(dates) => {
          handleFilterChange('date_from', dates ? dates[0].format('YYYY-MM-DD') : null);
          handleFilterChange('date_to', dates ? dates[1].format('YYYY-MM-DD') : null);
        }}
      />
      {isAdmin && (
        <Select
          style={{ width: 200 }}
          placeholder="Coordinator"
          value={filters.coordinator_id}
          onChange={(value) => handleFilterChange('coordinator_id', value)}
        >
          <Option value="">All</Option>
          <Option value="unassigned">Unassigned</Option>
          {coordinators.map(coordinator => (
            <Option key={coordinator.id} value={coordinator.id}>{coordinator.name}</Option>
          ))}
        </Select>
      )}
      <DatePicker
        style={{ width: 150 }}
        placeholder="Demo Date"
        value={filters.booking_date ? moment(filters.booking_date) : null}
        onChange={(date) => handleFilterChange('booking_date', date ? date.format('YYYY-MM-DD') : null)}
      />
      <TimePicker
        style={{ width: 150 }}
        placeholder="Demo Time"
        value={filters.booking_time ? moment(filters.booking_time, 'HH:mm:ss') : null}
        onChange={(time) => handleFilterChange('booking_time', time ? time.format('HH:mm:ss') : null)}
      />
      <Select
        style={{ width: 150 }}
        placeholder="Priority"
        value={filters.priority}
        onChange={(value) => handleFilterChange('priority', value)}
      >
        <Option value="">All</Option>
        <Option value="Low">Low</Option>
        <Option value="Medium">Medium</Option>
        <Option value="High">High</Option>
      </Select>
      <Button type="primary" onClick={fetchLeads}>Apply Filters</Button>
      <Button onClick={() => {
        setFilters({
          page: 1,
          per_page: 20,
          search: '',
          status: '',
          date_from: null,
          date_to: null,
          coordinator_id: currentUser.role === 'crm_executive' ? currentUser.id : '',
          demo_date: null,
          demo_time: null,
          priority: '',
        });
        fetchLeads();
      }}>Clear Filters</Button>
    </div>
    {isAdmin && (
      <div className="mb-4">
        <Select
          style={{ width: 200, marginRight: 10 }}
          placeholder="Select User to Assign"
          onChange={(value) => setBulkAssignUser(value)}
          value={bulkAssignUser}
        >
          {users.map(user => (
            <Option key={user.id} value={user.id}>{user.display_name}</Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleBulkAssign}>Bulk Assign</Button>
      </div>
    )}
    <Table
      dataSource={leads}
      columns={columns}
      rowKey="id"
      rowSelection={isAdmin ? {
        onChange: (selectedRowKeys) => setSelectedLeads(selectedRowKeys),
      } : null}
      pagination={{
        total: totalPages * filters.per_page,
        current: filters.page,
        pageSize: filters.per_page,
        onChange: (page) => setFilters(prev => ({ ...prev, page })),
      }}
      loading={loading}
      scroll={{ x: 'max-content' }}
      rowClassName={(record) => {
        if (record.recentlyUpdated) {
          return 'bg-green-100';
        } else if (record.notUpdated) {
          return 'bg-red-100';
        }
        return '';
      }}
    />
  </div>
);
};

LeadsManagementPage.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    username: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string,
    educational_qualification: PropTypes.string,
    designation: PropTypes.string,
    facebook_link: PropTypes.string,
    instagram_link: PropTypes.string,
    linkedin_link: PropTypes.string,
    bio: PropTypes.string,
    profile_image_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_image_url: PropTypes.string,
  }).isRequired,
};

export default LeadsManagementPage;