import React, { useState, useEffect } from 'react';
import { Card, Avatar, Typography, Button, Modal, Form, Input, Upload, message, Row, Col, DatePicker, List } from 'antd';
import { UserOutlined, EditOutlined, UploadOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import { getUserDetailsById, getSocialUpdates } from '../apiService';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { userId } = useParams();
  const [recentUpdates, setRecentUpdates] = useState([]);

  useEffect(() => {
    fetchUserProfile();
    fetchRecentUpdates();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const userData = await getUserDetailsById(userId);
      setUser(userData);
      form.setFieldsValue({
        ...userData,
        date_of_birth: userData.date_of_birth ? moment(userData.date_of_birth) : null,
        profile_image: userData.profile_image_url ? [{ url: userData.profile_image_url }] : []
      });
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Failed to load user profile');
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentUpdates = async () => {
    try {
      const updates = await getSocialUpdates({ userId, limit: 10 });
      setRecentUpdates(updates);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      message.error('Failed to load recent updates');
    }
  };

  const handleEditProfile = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === 'profile_image' && values[key] && values[key][0] && values[key][0].originFileObj) {
          formData.append(key, values[key][0].originFileObj);
        } else if (key === 'date_of_birth' && values[key]) {
          formData.append(key, values[key].format('YYYY-MM-DD'));
        } else if (values[key] !== undefined && values[key] !== null) {
          formData.append(key, values[key]);
        }
      });

      const token = localStorage.getItem('jwtToken');
      const response = await axios.post('https://niledutech.in/wp-json/ei-crm/v1/users/me/details', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setUser(response.data);
      message.success('Profile updated successfully');
      setEditModalVisible(false);
    } catch (error) {
      console.error('Error updating user profile:', error);
      message.error('Failed to update profile');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Card hoverable className="user-profile-card">
            <div className="text-center mb-4">
              <Avatar size={128} src={user.profile_image_url} icon={<UserOutlined />} />
            </div>
            <Title level={3} className="text-center mb-2">{user.display_name}</Title>
            <Text className="block text-center mb-4">{user.role}</Text>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setEditModalVisible(true)}
              className="w-full"
            >
              Edit Profile
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={16}>
          <Card hoverable className="user-details-card">
            <Title level={4}>User Details</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text strong>Email:</Text> {user.email}
              </Col>
              <Col span={12}>
                <Text strong>Date of Birth:</Text> {user.date_of_birth}
              </Col>
              <Col span={12}>
                <Text strong>Educational Qualification:</Text> {user.educational_qualification}
              </Col>
              <Col span={12}>
                <Text strong>Designation:</Text> {user.designation}
              </Col>
            </Row>
            <Paragraph className="mt-4">
              <Text strong>Bio:</Text> {user.bio}
            </Paragraph>
            <div className="mt-4">
              <Button type="link" href={user.facebook_link} target="_blank" icon={<FacebookOutlined />}>Facebook</Button>
              <Button type="link" href={user.instagram_link} target="_blank" icon={<InstagramOutlined />}>Instagram</Button>
              <Button type="link" href={user.linkedin_link} target="_blank" icon={<LinkedinOutlined />}>LinkedIn</Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Card hoverable className="mt-8 recent-updates-card">
        <Title level={4}>Recent Updates</Title>
        <List
          itemLayout="horizontal"
          dataSource={recentUpdates}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.user_avatar} />}
                title={item.content}
                description={moment(item.created_at).fromNow()}
              />
            </List.Item>
          )}
        />
      </Card>

      <Modal
        title="Edit Profile"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditProfile} layout="vertical">
          <Form.Item name="display_name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="date_of_birth" label="Date of Birth">
            <DatePicker />
          </Form.Item>
          <Form.Item name="educational_qualification" label="Educational Qualification">
            <Input />
          </Form.Item>
          <Form.Item name="designation" label="Designation">
            <Input />
          </Form.Item>
          <Form.Item name="facebook_link" label="Facebook Link">
            <Input />
          </Form.Item>
          <Form.Item name="instagram_link" label="Instagram Link">
            <Input />
          </Form.Item>
          <Form.Item name="linkedin_link" label="LinkedIn Link">
            <Input />
          </Form.Item>
          <Form.Item name="bio" label="Bio">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="profile_image" label="Profile Image">
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              fileList={form.getFieldValue('profile_image')}
            >
              <div>
                <UploadOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserProfile;