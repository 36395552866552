import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Statistic, Spin, Alert, Typography, Button, Modal, Select, Divider, Collapse } from 'antd';
import { UserOutlined, ReloadOutlined, DollarCircleOutlined, BarChartOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, TrophyOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { getLeads, getCoordinators, getCurrentUserDetails } from '../apiService';
import moment from 'moment';
import { 
  formatDateForAPI, 
  parseRevenue, 
} from '../utils/helpers';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const Dashboard = () => {
  const [leadsData, setLeadsData] = useState({
    today: 0,
    thisWeek: 0,
    thisMonth: 0,
  });
  const [revenue, setRevenue] = useState({
    expected: 0,
    generated: 0,
  });
  const [totalAdmissions, setTotalAdmissions] = useState(0);
  const [coordinatorStats, setCoordinatorStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalStats, setModalStats] = useState(null);
  const [statsPeriod, setStatsPeriod] = useState(30);
  const [currentUser, setCurrentUser] = useState(null);
  const [includeAdminLeads, setIncludeAdminLeads] = useState(true);
  const [leadComparison, setLeadComparison] = useState(null);

  const fetchLeadsForDateRange = async (startDate, endDate, coordinatorId = null) => {
    let allLeads = [];
    let currentPage = 1;
    let hasMorePages = true;

    while (hasMorePages) {
      try {
        const response = await getLeads({
          date_from: startDate,
          date_to: endDate,
          page: currentPage,
          per_page: 100,
          coordinator_id: coordinatorId
        });

        allLeads = [...allLeads, ...response.leads];

        if (response.leads.length < 100) {
          hasMorePages = false;
        } else {
          currentPage++;
        }
      } catch (error) {
        console.error('Error fetching leads:', error);
        hasMorePages = false;
      }
    }

    return allLeads;
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
  
      const userDetails = await getCurrentUserDetails();
      setCurrentUser(userDetails);
  
      const today = moment();
      const startOfWeek = moment().startOf('week');
      const startOfCurrentMonth = moment().startOf('month');
      const startOfPreviousMonth = moment().subtract(1, 'months').startOf('month');
      const endOfPreviousMonth = moment().subtract(1, 'months').endOf('month');
  
      const isAdmin = userDetails.role === 'administrator';
      const coordinatorId = isAdmin ? null : userDetails.id;
  
      const [todayLeads, weekLeads, currentMonthLeads, previousMonthLeads, allLeads] = await Promise.all([
        fetchLeadsForDateRange(formatDateForAPI(today), formatDateForAPI(today), coordinatorId),
        fetchLeadsForDateRange(formatDateForAPI(startOfWeek), formatDateForAPI(today), coordinatorId),
        fetchLeadsForDateRange(formatDateForAPI(startOfCurrentMonth), formatDateForAPI(today), coordinatorId),
        fetchLeadsForDateRange(formatDateForAPI(startOfPreviousMonth), formatDateForAPI(endOfPreviousMonth), coordinatorId),
        fetchLeadsForDateRange(formatDateForAPI(startOfPreviousMonth), formatDateForAPI(today), coordinatorId)
      ]);
  
      setLeadsData({
        today: todayLeads.length,
        thisWeek: weekLeads.length,
        thisMonth: currentMonthLeads.length,
      });
  
      const currentMonthAdmissions = currentMonthLeads.filter(lead => lead.status === 'Admission').length;
      const previousMonthAdmissions = previousMonthLeads.filter(lead => lead.status === 'Admission').length;
  
      const leadPercentageChange = previousMonthLeads.length > 0
        ? ((currentMonthLeads.length - previousMonthLeads.length) / previousMonthLeads.length) * 100
        : 100;
      const admissionPercentageChange = previousMonthAdmissions > 0
        ? ((currentMonthAdmissions - previousMonthAdmissions) / previousMonthAdmissions) * 100
        : 100;
  
      setLeadComparison({
        currentMonth: {
          leads: currentMonthLeads.length,
          admissions: currentMonthAdmissions,
        },
        previousMonth: {
          leads: previousMonthLeads.length,
          admissions: previousMonthAdmissions,
        },
        leadPercentageChange,
        admissionPercentageChange,
      });
  
      if (isAdmin) {
        const { expectedRevenue, generatedRevenue, totalAdmissions } = calculateOverallStats(allLeads);
        setRevenue({
          expected: expectedRevenue,
          generated: generatedRevenue,
        });
        setTotalAdmissions(totalAdmissions);
  
        const coordinators = await getCoordinators();
        const adminUser = { id: 'admin', name: 'Administrators' };
        const allUsers = [...coordinators, adminUser];
  
        const coordinatorStatsPromises = allUsers.map(async (user) => {
          const userLeads = await fetchLeadsForDateRange(
            formatDateForAPI(startOfPreviousMonth), 
            formatDateForAPI(today), 
            user.id === 'admin' ? null : user.id
          );
          const stats = calculateStats(userLeads);
          return {
            id: user.id,
            name: user.name,
            ...stats,
          };
        });
  
        const resolvedCoordinatorStats = await Promise.all(coordinatorStatsPromises);
        setCoordinatorStats(resolvedCoordinatorStats);
      } else {
        const stats = calculateStats(allLeads);
        setCoordinatorStats([{ id: userDetails.id, name: userDetails.display_name, ...stats }]);
        
        // Set revenue and total admissions for non-admin users
        setRevenue({
          expected: stats.expectedRevenue,
          generated: stats.revenueGenerated,
        });
        setTotalAdmissions(stats.totalAdmissions);
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please try again later.');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calculateOverallStats = (leads) => {
    const stats = {
      expectedRevenue: 0,
      generatedRevenue: 0,
      totalAdmissions: 0,
    };

    const currentMonth = moment().month();
    const currentYear = moment().year();

    leads.forEach(lead => {
      const leadDate = moment(lead.date);
      const isCurrentMonth = leadDate.month() === currentMonth && leadDate.year() === currentYear;
      const isPreviousMonth = leadDate.month() === (currentMonth - 1 + 12) % 12 && 
                              (currentMonth === 0 ? leadDate.year() === currentYear - 1 : leadDate.year() === currentYear);

      if (lead.status === 'Admission') {
        if (isCurrentMonth) {
          stats.generatedRevenue += parseRevenue(lead.revenue);
          stats.totalAdmissions++;
        } else if (isPreviousMonth && lead.status_changed_at && moment(lead.status_changed_at).month() === currentMonth) {
          stats.generatedRevenue += parseRevenue(lead.revenue);
          stats.totalAdmissions++;
        }
      } else if (!['Admission', 'Closed'].includes(lead.status)) {
        if (isCurrentMonth || (isPreviousMonth && lead.status === 'Follow Up')) {
          stats.expectedRevenue += parseRevenue(lead.revenue);
        }
      }
    });

    return stats;
  };

  const calculateStats = (leads) => {
    const stats = {
      totalFollowUps: 0,
      demoBooked: 0,
      demoMissed: 0,
      demoDone: 0,
      closed: 0,
      totalNP: 0,
      unconfirmed: 0,
      untouched: 0,
      revenueGenerated: 0,
      expectedRevenue: 0,
      totalAdmissions: 0,
    };

    const currentMonth = moment().month();
    const currentYear = moment().year();

    leads.forEach(lead => {
      const leadDate = moment(lead.date);
      const isCurrentMonth = leadDate.month() === currentMonth && leadDate.year() === currentYear;
      const isPreviousMonth = leadDate.month() === (currentMonth - 1 + 12) % 12 && 
                              (currentMonth === 0 ? leadDate.year() === currentYear - 1 : leadDate.year() === currentYear);

      switch (lead.status) {
        case 'Follow Up':
          stats.totalFollowUps++;
          break;
        case 'Demo Booked':
          stats.demoBooked++;
          break;
        case 'Demo Missed':
          stats.demoMissed++;
          break;
        case 'Demo Done':
          stats.demoDone++;
          break;
        case 'Closed':
          stats.closed++;
          break;
        case 'NP1':
        case 'NP2':
        case 'NP3':
          stats.totalNP++;
          break;
        case 'Unconfirmed':
          stats.unconfirmed++;
          break;
        case 'Admission':
          if (isCurrentMonth) {
            stats.revenueGenerated += parseRevenue(lead.revenue);
            stats.totalAdmissions++;
          } else if (isPreviousMonth && lead.status_changed_at && moment(lead.status_changed_at).month() === currentMonth) {
            stats.revenueGenerated += parseRevenue(lead.revenue);
            stats.totalAdmissions++;
          }
          break;
        default:
          if (!lead.status) stats.untouched++;
      }

      if (!['Admission', 'Closed'].includes(lead.status)) {
        if (isCurrentMonth || (isPreviousMonth && lead.status === 'Follow Up')) {
          stats.expectedRevenue += parseRevenue(lead.revenue);
        }
      }
    });

    return stats;
  };

  const handleCoordinatorClick = (coordinator) => {
    setSelectedCoordinator(coordinator);
    setModalVisible(true);
    fetchCoordinatorStats(coordinator.id);
  };

  const fetchCoordinatorStats = async (coordinatorId) => {
    setModalLoading(true);
    try {
      const endDate = formatDateForAPI(new Date());
      const startDate = formatDateForAPI(moment().subtract(statsPeriod, 'days').toDate());

      const leads = await fetchLeadsForDateRange(startDate, endDate, coordinatorId === 'admin' ? null : coordinatorId);

      const stats = calculateStats(leads);
      setModalStats(stats);
    } catch (error) {
      console.error('Error fetching coordinator stats:', error);
      setError('Failed to load coordinator statistics. Please try again.');
    } finally {
      setModalLoading(false);
    }
  };

  const renderCoordinatorStats = (stats) => (
    <Card className="coordinator-stats-card">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Total Admissions" 
            value={stats.totalAdmissions} 
            prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
            valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Total Follow Ups" 
            value={stats.totalFollowUps} 
            prefix={<PhoneOutlined style={{ color: '#1890ff' }} />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Demo Booked" 
            value={stats.demoBooked} 
            prefix={<CalendarOutlined style={{ color: '#52c41a' }} />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Demo Done" 
            value={stats.demoDone} 
            prefix={<CheckCircleOutlined style={{ color: '#13c2c2' }} />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Demo Missed" 
            value={stats.demoMissed} 
            prefix={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Closed" 
            value={stats.closed} 
            prefix={<CheckCircleOutlined style={{ color: '#faad14' }} />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic 
            title="Total NP" 
            value={stats.totalNP} 
            prefix={<UserOutlined style={{ color: '#722ed1' }} />}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Statistic
            title="Expected Revenue"
            value={stats.expectedRevenue}
            precision={2}
            prefix={<DollarCircleOutlined style={{ color: '#722ed1' }} />}
            suffix="₹"
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Generated Revenue"
            value={stats.revenueGenerated}
            precision={2}
            prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
            suffix="₹"
          />
        </Col>
      </Row>
    </Card>
  );

  const renderLeadComparisonGraph = () => {
    if (!leadComparison) return null;

    const data = [
      {
        name: 'Previous Month',
        Leads: leadComparison.previousMonth.leads,
        Admissions: leadComparison.previousMonth.admissions,
      },
      {
        name: 'Current Month',
        Leads: leadComparison.currentMonth.leads,
        Admissions: leadComparison.currentMonth.admissions,
      },
    ];

    return (
      <Card title="Lead and Admission Comparison">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Leads" fill="#8884d8" />
            <Bar dataKey="Admissions" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
        <Divider />
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="Lead Change"
              value={leadComparison.leadPercentageChange}
              precision={2}
              valueStyle={{ color: leadComparison.leadPercentageChange >= 0 ? '#3f8600' : '#cf1322' }}
              prefix={leadComparison.leadPercentageChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix="%"
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Admission Change"
              value={leadComparison.admissionPercentageChange}
              precision={2}
              valueStyle={{ color: leadComparison.admissionPercentageChange >= 0 ? '#3f8600' : '#cf1322' }}
              prefix={leadComparison.admissionPercentageChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix="%"
            />
          </Col>
        </Row>
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Dashboard</Title>
        <Button icon={<ReloadOutlined />} onClick={fetchData}>Refresh</Button>
      </div>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Statistic
              title="Total Leads Today"
              value={leadsData.today}
              prefix={<UserOutlined style={{ color: '#1890ff' }} />}
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Statistic
              title="Total Leads This Week"
              value={leadsData.thisWeek}
              prefix={<BarChartOutlined style={{ color: '#52c41a' }} />}
              valueStyle={{ color: '#52c41a' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Statistic
              title="Total Leads This Month"
              value={leadsData.thisMonth}
              prefix={<PhoneOutlined style={{ color: '#faad14' }} />}
              valueStyle={{ color: '#faad14' }}
            />
          </Card>
        </Col>
      </Row>

      {currentUser && currentUser.role === 'administrator' && (
        <Row gutter={[16, 16]} className="mt-6">
          <Col xs={24} md={8}>
            <Card>
              <Statistic
                title="Total Admissions This Month"
                value={totalAdmissions}
                prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
              />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card>
              <Statistic
                title="Expected Revenue"
                value={revenue.expected}
                precision={2}
                prefix={<DollarCircleOutlined style={{ color: '#722ed1' }} />}
                suffix="₹"
                valueStyle={{ color: '#722ed1' }}
              />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card>
              <Statistic
                title="Generated Revenue"
                value={revenue.generated}
                precision={2}
                prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
                suffix="₹"
                valueStyle={{ color: '#13c2c2' }}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]} className="mt-6">
        <Col xs={24}>
          {renderLeadComparisonGraph()}
        </Col>
      </Row>

      <Divider />

      {currentUser && currentUser.role === 'administrator' ? (
        <Row gutter={[16, 16]} className="mt-6">
          <Col xs={24}>
            <Card 
              title="Coordinator Statistics" 
              extra={
                <Select
                  defaultValue={includeAdminLeads}
                  style={{ width: 200 }}
                  onChange={(value) => setIncludeAdminLeads(value)}
                >
                  <Option value={true}>Include Admin Leads</Option>
                  <Option value={false}>Exclude Admin Leads</Option>
                </Select>
              }
            >
              <Collapse>
                {(includeAdminLeads ? coordinatorStats : coordinatorStats.filter(stat => stat.id !== 'admin')).map((coordinator) => (
                  <Panel header={coordinator.name} key={coordinator.id}>
                    {renderCoordinatorStats(coordinator)}
                    <Button onClick={() => handleCoordinatorClick(coordinator)} style={{ marginTop: '16px' }}>
                      View Detailed Stats
                    </Button>
                  </Panel>
                ))}
              </Collapse>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="mt-6">
          <Col xs={24}>
            <Card title="Your Performance">
              {coordinatorStats.length > 0 && renderCoordinatorStats(coordinatorStats[0])}
            </Card>
          </Col>
        </Row>
      )}

      <Modal
        title={`${selectedCoordinator?.name}'s Statistics (Last ${statsPeriod} days)`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <Select
          value={statsPeriod}
          onChange={(value) => {
            setStatsPeriod(value);
            if (selectedCoordinator) {
              fetchCoordinatorStats(selectedCoordinator.id);
            }
          }}
          style={{ marginBottom: 16 }}
        >
          <Option value={30}>Last 30 Days</Option>
          <Option value={60}>Last 60 Days</Option>
          <Option value={90}>Last 90 Days</Option>
        </Select>
        {modalLoading ? (
          <Spin size="large" />
        ) : modalStats ? (
          renderCoordinatorStats(modalStats)
        ) : null}
      </Modal>
    </div>
  );
};

export default Dashboard;