import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Leads from './components/Leads';
import TaskList from './components/TaskList';
import CannedResponses from './components/CannedResponses';
import Reminders from './components/Reminders';
import Navbar from './components/Navbar';
import UserNotes from './components/UserNotes';
import Wiki from './components/Wiki';
import SocialUpdates from './components/SocialUpdates';
import UserProfile from './components/UserProfile';
import GlobalChat from './components/GlobalChat';
import ReminderPopup from './components/ReminderPopup';
import { logout, getCurrentUserDetails } from './apiService';

const theme = createTheme();

const App = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    currentUser: null,
    loading: true,
    fullyAuthenticated: false,
    token: null
  });
  const [notesVisible, setNotesVisible] = useState(false);

  const fetchUserDetails = useCallback(async (token) => {
    try {
      const userDetails = await getCurrentUserDetails();
      setAuthState({
        isAuthenticated: true,
        currentUser: userDetails,
        loading: false,
        fullyAuthenticated: false,
        token: token
      });

      setTimeout(() => {
        setAuthState(prev => ({ ...prev, fullyAuthenticated: true }));
      }, 20000);
    } catch (error) {
      console.error('Error fetching user details:', error);
      localStorage.removeItem('jwtToken');
      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        loading: false,
        fullyAuthenticated: false,
        token: null
      });
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      fetchUserDetails(token);
    } else {
      setAuthState(prev => ({ ...prev, loading: false }));
    }
  }, [fetchUserDetails]);

  const handleLogin = useCallback((token) => {
    localStorage.setItem('jwtToken', token);
    fetchUserDetails(token);
  }, [fetchUserDetails]);

  const handleLogout = useCallback(() => {
    logout();
    setAuthState({
      isAuthenticated: false,
      currentUser: null,
      loading: false,
      fullyAuthenticated: false,
      token: null
    });
    localStorage.removeItem('jwtToken');
  }, []);

  const toggleNotes = useCallback(() => {
    setNotesVisible(prev => !prev);
  }, []);

  if (authState.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          {authState.isAuthenticated && authState.currentUser && (
            <Navbar 
              logout={handleLogout} 
              currentUser={authState.currentUser}
              fullyAuthenticated={authState.fullyAuthenticated}
            />
          )}
          <Routes>
            <Route 
              path="/login" 
              element={
                !authState.isAuthenticated ? (
                  <Login onLogin={handleLogin} />
                ) : (
                  <Navigate to="/social" replace />
                )
              } 
            />
            <Route 
              path="/social" 
              element={
                authState.isAuthenticated ? (
                  <SocialUpdates currentUser={authState.currentUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/dashboard" 
              element={
                authState.isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />
              } 
            />
            <Route 
              path="/leads" 
              element={
                authState.isAuthenticated && authState.currentUser ? (
                  <Leads currentUser={authState.currentUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/tasks" 
              element={
                authState.isAuthenticated ? (
                  <TaskList currentUser={authState.currentUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/canned-responses" 
              element={
                authState.isAuthenticated ? <CannedResponses /> : <Navigate to="/login" replace />
              } 
            />
            <Route 
              path="/reminders" 
              element={
                authState.isAuthenticated ? (
                  <Reminders currentUser={authState.currentUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/wiki" 
              element={
                authState.isAuthenticated ? <Wiki /> : <Navigate to="/login" replace />
              } 
            />
            <Route 
              path="/user/:userId" 
              element={
                authState.isAuthenticated ? (
                  <UserProfile currentUser={authState.currentUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="/" element={<Navigate to="/social" replace />} />
          </Routes>
          {authState.isAuthenticated && authState.currentUser && (
            <>
              <UserNotes 
                visible={notesVisible} 
                onClose={() => setNotesVisible(false)} 
                token={authState.token}
              />
              <GlobalChat />
              <ReminderPopup />
              <button
                onClick={toggleNotes}
                style={{
                  position: 'fixed',
                  right: '0',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(-90deg)',
                  transformOrigin: 'right bottom',
                  zIndex: 1000,
                  padding: '10px 20px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px 5px 0 0',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  boxShadow: '-2px 0 5px rgba(0,0,0,0.2)'
                }}
              >
                Notes
              </button>
            </>
          )}
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;