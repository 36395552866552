import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, TimePicker, Select, Popover, message, Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { updateLead, getNotes, addNote, getCoordinators } from '../apiService';
import NotesPopover from './NotesPopover';

const { Option } = Select;

const LeadDetailsModal = ({ visible, onClose, lead, onUpdate }) => {
  const [form] = Form.useForm();
  const [notes, setNotes] = useState([]);
  const [coordinators, setCoordinators] = useState([]);

  useEffect(() => {
    if (lead) {
      form.setFieldsValue({
        ...lead,
        booking_date: lead.booking_date ? moment(lead.booking_date) : null,
        booking_time: lead.booking_time ? moment(lead.booking_time, 'HH:mm:ss') : null,
        call_date: lead.call_date ? moment(lead.call_date) : null,
        next_call_date: lead.next_call_date ? moment(lead.next_call_date) : null,
      });
      fetchNotes();
      fetchCoordinators();
    }
  }, [lead, form]);

  const fetchNotes = async () => {
    if (lead) {
      try {
        const fetchedNotes = await getNotes(lead.id);
        setNotes(fetchedNotes);
      } catch (error) {
        console.error('Error fetching notes:', error);
        message.error('Failed to load notes');
      }
    }
  };

  const fetchCoordinators = async () => {
    try {
      const response = await getCoordinators();
      setCoordinators(response);
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      message.error('Failed to load coordinators');
    }
  };

  const handleCellEdit = async (key, dataIndex, value) => {
    try {
      let updatedValue = value;
      if (['booking_date', 'call_date', 'next_call_date'].includes(dataIndex)) {
        updatedValue = value ? value.format('YYYY-MM-DD') : null;
      } else if (dataIndex === 'booking_time') {
        updatedValue = value ? value.format('HH:mm:ss') : null;
      }

      const updatedLead = {
        ...lead,
        [dataIndex]: updatedValue
      };

      await updateLead(key, updatedLead);
      
      if (typeof onUpdate === 'function') {
        onUpdate(updatedLead);
      }
      
      message.success('Lead updated successfully');
    } catch (error) {
      console.error('Error updating lead:', error);
      message.error('Failed to update lead');
    }
  };

  const handleAddNote = async (noteContent) => {
    try {
      await addNote(lead.id, noteContent);
      message.success('Note added successfully');
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  if (!lead) return null;

  const lastNote = notes.length > 0 ? notes[0].note_content : 'No notes';

  return (
    <Modal
      title="Lead Details"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input
                onPressEnter={(e) => handleCellEdit(lead.id, 'name', e.target.value)}
                onBlur={(e) => handleCellEdit(lead.id, 'name', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone" label="Phone">
              <Input
                onPressEnter={(e) => handleCellEdit(lead.id, 'phone', e.target.value)}
                onBlur={(e) => handleCellEdit(lead.id, 'phone', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="course" label="Course">
          <Input
            onPressEnter={(e) => handleCellEdit(lead.id, 'course', e.target.value)}
            onBlur={(e) => handleCellEdit(lead.id, 'course', e.target.value)}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="booking_date" label="Booking Date">
              <DatePicker
                format="D-MMM-YYYY"
                onChange={(date) => handleCellEdit(lead.id, 'booking_date', date)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="booking_time" label="Booking Time">
              <TimePicker
                format="hh:mm A"
                use12Hours
                onChange={(time) => handleCellEdit(lead.id, 'booking_time', time)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="demo_language" label="Demo Language">
          <Input
            onPressEnter={(e) => handleCellEdit(lead.id, 'demo_language', e.target.value)}
            onBlur={(e) => handleCellEdit(lead.id, 'demo_language', e.target.value)}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="call_date" label="Call Date">
              <DatePicker
                showTime
                format="D-MMM-YYYY hh:mm A"
                use12Hours
                onChange={(date) => handleCellEdit(lead.id, 'call_date', date)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="next_call_date" label="Next Call Date">
              <DatePicker
                showTime
                format="D-MMM-YYYY hh:mm A"
                use12Hours
                onChange={(date) => handleCellEdit(lead.id, 'next_call_date', date)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="status" label="Status">
              <Select onChange={(value) => handleCellEdit(lead.id, 'status', value)}>
                <Option value={null}>Not Set</Option>
                {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 'Follow Up', 'Details Shared', 'Offer Given', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].map(status => (
                  <Option key={status} value={status}>{status}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="coordinator_id" label="Coordinator">
              <Select onChange={(value) => handleCellEdit(lead.id, 'coordinator_id', value)}>
                <Option value={null}>Not Assigned</Option>
                {coordinators.map(coordinator => (
                  <Option key={coordinator.id} value={coordinator.id}>{coordinator.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="priority" label="Priority">
              <Select onChange={(value) => handleCellEdit(lead.id, 'priority', value)}>
                <Option value={null}>Not Set</Option>
                <Option value="Low">Low</Option>
                <Option value="Medium">Medium</Option>
                <Option value="High">High</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="revenue" label="Revenue">
              <Input
                prefix="₹"
                onPressEnter={(e) => handleCellEdit(lead.id, 'revenue', e.target.value)}
                onBlur={(e) => handleCellEdit(lead.id, 'revenue', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Notes">
          <Popover
            content={
              <NotesPopover
                leadId={Number(lead.id)}
                phone={lead.phone}
                addNote={handleAddNote}
                notes={notes}
                refreshNotes={fetchNotes}
              />
            }
            title="Notes and Logs"
            trigger="click"
          >
            <div style={{ cursor: 'pointer' }}>{lastNote}</div>
          </Popover>
        </Form.Item>
      </Form>
    </Modal>
  );
};

LeadDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    phone: PropTypes.string,
    course: PropTypes.string,
    booking_date: PropTypes.string,
    booking_time: PropTypes.string,
    demo_language: PropTypes.string,
    coordinator_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coordinator_name: PropTypes.string,
    call_date: PropTypes.string,
    status: PropTypes.string,
    next_call_date: PropTypes.string,
    priority: PropTypes.string,
    revenue: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
};

export default LeadDetailsModal;