import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Collapse, Form, Input, DatePicker, Select, Button, message, Space } from 'antd';
import moment from 'moment-timezone';
import alert from './alert.mp3';
import { getNotifications, updateReminder, getReminder, markNotificationAsRead } from '../apiService';

const { Panel } = Collapse;
const { Option } = Select;

const ReminderPopup = () => {
  const [reminders, setReminders] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await getNotifications();
      const reminderNotifications = response.filter(notification => 
        notification.type === 'reminder' && notification.is_read === "0"
      );

      const reminderDetails = await Promise.all(reminderNotifications.map(async notification => {
        const reminder = notification.reminder || await getReminder(notification.reference_id);
        return {
          ...reminder,
          notificationId: notification.id,
          message: notification.message
        };
      }));

      // Filter out snoozed reminders
      const now = moment();
      const activeReminders = reminderDetails.filter(reminder => {
        const snoozeInfo = JSON.parse(localStorage.getItem(`snooze_${reminder.id}`));
        return !snoozeInfo || moment(snoozeInfo.until) <= now;
      });

      setReminders(activeReminders);
      if (activeReminders.length > 0 && !visible) {
        setVisible(true);
        const sound = new Audio(alert);
        sound.play().catch(error => console.error('Error playing sound:', error));
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      message.error('Failed to load notifications');
    }
  }, [visible]);

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [fetchNotifications]);

  const handleUpdate = async (values) => {
    try {
      const reminderData = {
        ...values,
        datetime: moment.tz(values.datetime, 'Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss+00:00')
      };

      // Remove any undefined or null values
      Object.keys(reminderData).forEach(key => reminderData[key] === undefined && delete reminderData[key]);

      console.log('Updating reminder with data:', JSON.stringify(reminderData, null, 2));

      await updateReminder(values.id, reminderData);
      message.success('Reminder updated successfully');
      form.resetFields();
      fetchNotifications();
    } catch (error) {
      console.error('Error updating reminder:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      message.error('Failed to update reminder');
    }
  };

  const handleComplete = async (reminderId, notificationId) => {
    try {
      const reminderDetails = await getReminder(reminderId);
      if (reminderDetails.status === 'completed') {
        message.info('Reminder is already completed');
        return;
      }

      const updateData = {
        ...reminderDetails,
        status: 'completed',
        datetime: moment.utc(reminderDetails.datetime).format('YYYY-MM-DDTHH:mm:ss+00:00')
      };

      // Remove any undefined or null values
      Object.keys(updateData).forEach(key => updateData[key] === undefined && delete updateData[key]);

      console.log('Completing reminder with data:', JSON.stringify(updateData, null, 2));

      await updateReminder(reminderId, updateData);
      await markNotificationAsRead(notificationId);
      message.success('Reminder marked as complete');
      fetchNotifications();
    } catch (error) {
      console.error('Error completing reminder:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      message.error('Failed to complete reminder');
    }
  };

  const handleSnooze = (reminderId, minutes) => {
    const snoozeUntil = moment().add(minutes, 'minutes');
    localStorage.setItem(`snooze_${reminderId}`, JSON.stringify({
      until: snoozeUntil.toISOString(),
      duration: minutes
    }));
    message.success(`Reminder snoozed for ${minutes} minutes`);
    fetchNotifications();
  };

  const renderSnoozeButtons = (reminderId) => (
    <Space>
      {[5, 10, 15, 20, 30].map(minutes => (
        <Button key={minutes} onClick={() => handleSnooze(reminderId, minutes)}>
          Snooze {minutes}m
        </Button>
      ))}
    </Space>
  );

  return (
    <Modal
      title="Reminder Notifications"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
    >
      <Collapse accordion>
        {reminders.map(reminder => (
          <Panel header={`${reminder.type}: ${reminder.message}`} key={reminder.id}>
            <Form
              form={form}
              initialValues={{
                ...reminder,
                datetime: reminder.datetime ? moment.tz(reminder.datetime, 'Asia/Kolkata') : null
              }}
              onFinish={handleUpdate}
            >
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type" label="Type">
                <Select>
                  <Option value="booking_date">Booking Date</Option>
                  <Option value="next_call_date">Next Call Date</Option>
                  <Option value="meeting">Meeting</Option>
                </Select>
              </Form.Item>
              <Form.Item name="datetime" label="Date and Time">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  use12Hours
                  inputReadOnly
                />
              </Form.Item>
              <Form.Item name="message" label="Message">
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    Update
                  </Button>
                  <Button onClick={() => handleComplete(reminder.id, reminder.notificationId)}>
                    Mark as Complete
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            {renderSnoozeButtons(reminder.id)}
          </Panel>
        ))}
      </Collapse>
    </Modal>
  );
};

export default ReminderPopup;