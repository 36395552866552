import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Button, Badge, Avatar, Upload, message, Modal } from 'antd';
import { SendOutlined, MessageOutlined, PictureOutlined, UserOutlined, DeleteOutlined, PhoneOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { getGlobalChatMessages, sendGlobalChatMessage, markGlobalChatMessagesAsRead, getUserDetails } from '../apiService';
import moment from 'moment-timezone';
import styled from 'styled-components';

const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: ${props => props.isOpen ? '400px' : '250px'};
  height: ${props => props.isOpen ? '600px' : '50px'};
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  background-color: #d14d72;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: ${props => props.isOpen ? 'auto' : '50px'};
`;

const MinimizedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ChatBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const MessageGroup = styled.div`
  margin-bottom: 20px;
`;

const Timestamp = styled.div`
  text-align: center;
  color: #999;
  font-size: 12px;
  margin-bottom: 10px;
`;

const Message = styled.div`
  display: flex;
  margin-bottom: 10px;
  ${props => props.sent && 'flex-direction: row-reverse;'}
`;

const MessageContent = styled.div`
  max-width: 70%;
  padding: 10px;
  border-radius: 18px;
  ${props => props.sent ? `
    background-color: #d14d72;
    color: white;
    border-bottom-right-radius: 4px;
  ` : `
    background-color: #f0f2f5;
    border-bottom-left-radius: 4px;
  `}
`;

const ChatInput = styled.div`
  display: flex;
  padding: 10px;
  background-color: white;
`;

const StyledInput = styled(Input)`
  border-radius: 20px;
  margin-right: 10px;
`;

const ImagePreview = styled.div`
  margin-top: 10px;
  position: relative;
  display: inline-block;
`;

const PreviewImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const DeleteImageButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.8);
`;

const GlobalChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const fetchMessages = useCallback(async () => {
    try {
      const response = await getGlobalChatMessages();
      setMessages(response);
      const newUnreadCount = response.filter(msg => !msg.is_read).length;
      setUnreadCount(newUnreadCount);
    } catch (error) {
      console.error('Error fetching messages:', error);
      message.error('Failed to load messages. Please try again later.');
    }
  }, []);

  const fetchCurrentUser = useCallback(async () => {
    try {
      const user = await getUserDetails();
      setCurrentUser(user);
    } catch (error) {
      console.error('Error fetching current user:', error);
      message.error('Failed to load user details. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchCurrentUser();
    fetchMessages();
    const interval = setInterval(fetchMessages, 10000);
    return () => clearInterval(interval);
  }, [fetchMessages, fetchCurrentUser]);

  useEffect(() => {
    if (isOpen) {
      markGlobalChatMessagesAsRead().catch(error => {
        console.error('Error marking messages as read:', error);
      });
      setUnreadCount(0);
    }
  }, [isOpen]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleImageUpload = ({ file }) => {
    if (file.status === 'done') {
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    } else {
      // This will trigger for 'uploading' status
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file.originFileObj);
      setImageFile(file.originFileObj);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImagePreview(null);
  };

  const handleSendMessage = () => {
    if ((!newMessage.trim() && !imageFile) || sendingMessage) return;
    if (imageFile) {
      setPreviewModalVisible(true);
    } else {
      confirmSendMessage();
    }
  };

  const confirmSendMessage = async () => {
    setSendingMessage(true);
    try {
      const response = await sendGlobalChatMessage(newMessage.trim(), imageFile);
      if (response && response.id) {
        setNewMessage('');
        setImageFile(null);
        setImagePreview(null);
        message.success('Message sent successfully');
        fetchMessages();
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('Failed to send message. Please try again.');
    } finally {
      setSendingMessage(false);
      setPreviewModalVisible(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const messageDate = moment.utc(timestamp).tz('Asia/Kolkata');
    const today = moment().tz('Asia/Kolkata');
    
    if (messageDate.isSame(today, 'day')) {
      return `Today ${messageDate.format('hh:mm A')}`;
    } else {
      return messageDate.format('D-MMM-YYYY hh:mm A');
    }
  };

  const groupMessagesByDate = (msgs) => {
    const groups = {};
    msgs.forEach(msg => {
      const date = moment(msg.created_at).format('MMM D, YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].unshift(msg);
    });
    return Object.entries(groups).sort((a, b) => moment(a[0], 'MMM D, YYYY').diff(moment(b[0], 'MMM D, YYYY')));
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (chatContainerRef.current && !chatContainerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleImageClick = (imageUrl, messageText) => {
    setSelectedImage({ url: imageUrl, message: messageText });
  };

  return (
    <ChatContainer ref={chatContainerRef} isOpen={isOpen}>
      <ChatHeader onClick={toggleChat} isOpen={isOpen}>
        {isOpen ? (
          <>
            <div>
              <Badge count={unreadCount}>
                <MessageOutlined style={{ marginRight: '10px' }} />
              </Badge>
              Global Chat
            </div>
            <div>
              <PhoneOutlined style={{ marginRight: 15 }} />
              <VideoCameraOutlined />
            </div>
          </>
        ) : (
          <MinimizedHeader>
            <Badge count={unreadCount}>
              <MessageOutlined style={{ marginRight: '10px' }} />
            </Badge>
            <span>Global Chat</span>
          </MinimizedHeader>
        )}
      </ChatHeader>
      {isOpen && (
        <>
          <ChatBody>
            {groupMessagesByDate(messages).map(([date, groupMessages]) => (
              <MessageGroup key={date}>
                <Timestamp>{date}</Timestamp>
                {groupMessages.map((msg) => (
                  <Message key={msg.id} sent={msg.user_id === currentUser?.id}>
                    <Avatar src={msg.profile_image_url || undefined} icon={!msg.profile_image_url && <UserOutlined />} />
                    <MessageContent sent={msg.user_id === currentUser?.id}>
                      {msg.message}
                      {msg.image_url && (
                        <img 
                          src={msg.image_url} 
                          alt="Attached" 
                          style={{ maxWidth: '100%', marginTop: '5px', borderRadius: '8px', cursor: 'pointer' }} 
                          onClick={() => handleImageClick(msg.image_url, msg.message)}
                        />
                      )}
                      <div style={{ fontSize: '10px', marginTop: '5px' }}>{formatTimestamp(msg.created_at)}</div>
                    </MessageContent>
                  </Message>
                ))}
              </MessageGroup>
            ))}
            <div ref={messagesEndRef} />
          </ChatBody>
          <ChatInput>
            <StyledInput
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onPressEnter={handleSendMessage}
              placeholder="Write something..."
              disabled={sendingMessage}
            />
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={(file) => {
                const isImage = file.type.startsWith('image/');
                if (!isImage) {
                  message.error(`${file.name} is not an image file`);
                }
                return isImage || Upload.LIST_IGNORE;
              }}
              customRequest={({onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok");
                }, 0);
              }}
              onChange={handleImageUpload}
              disabled={sendingMessage}
            >
              <Button icon={<PictureOutlined />} disabled={sendingMessage} />
            </Upload>
            <Button 
              type="primary"
              icon={<SendOutlined />} 
              onClick={handleSendMessage} 
              disabled={(!newMessage.trim() && !imageFile) || sendingMessage}
              loading={sendingMessage}
            />
          </ChatInput>
          {imagePreview && (
            <ImagePreview>
              <PreviewImage src={imagePreview} alt="Preview" />
              <DeleteImageButton 
                icon={<DeleteOutlined />} 
                onClick={handleRemoveImage} 
                size="small"
              />
            </ImagePreview>
          )}
        </>
      )}
      <Modal
        title="Preview Message"
        visible={previewModalVisible}
        onOk={confirmSendMessage}
        onCancel={() => setPreviewModalVisible(false)}
        confirmLoading={sendingMessage}
      >
        <p>{newMessage}</p>
        {imagePreview && <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
      </Modal>
      <Modal
        visible={!!selectedImage}
        footer={null}
        onCancel={() => setSelectedImage(null)}
        width="80%"
      >
        {selectedImage && (
          <>
            <img src={selectedImage.url} alt="Full size" style={{ width: '100%' }} />
            <p style={{ marginTop: '10px' }}>{selectedImage.message}</p>
          </>
        )}
      </Modal>
    </ChatContainer>
  );
};

export default GlobalChat;