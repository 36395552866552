import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, message, Modal, Form, Input, DatePicker, Select, Row, Col, Typography, Spin, Badge, Avatar, Alert, Divider } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, ClockCircleOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { getTasks, createTask, updateTask, deleteTask, getAllUsers } from '../apiService';
import axios from 'axios';
import moment from 'moment-timezone';
import styled from 'styled-components';

const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const PageWrapper = styled.div`
  background-color: #f0f2f5;
  min-height: 100vh;
  padding: 24px;
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.isDueNow ? '#FFCCCB' : props.isApproaching ? '#E6F7FF' : 'white'};

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  .ant-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .ant-card-actions {
    background-color: #f0f2f5;
    border-top: 1px solid #e8e8e8;
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const TaskTitle = styled(Title)`
  margin-bottom: 0 !important;
  flex: 1;
  margin-right: 16px;
`;

const TaskDescription = styled(Paragraph)`
  margin-bottom: 16px;
  flex-grow: 1;
`;

const TaskMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MetaItem = styled.div`
  display: flex;
  align-items: center;
`;

const DueBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
`;

const TaskList = ({ currentUser }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  const formatDateTimeForDisplay = (dateTimeString) => {
    return moment(dateTimeString).tz('Asia/Kolkata').format('D-MMM-YYYY hh:mm A');
  };

  const formatDateTimeForAPI = (dateTime) => {
    return moment(dateTime).tz('UTC').format('YYYY-MM-DDTHH:mm:ss+00:00');
  };

  const parseDateTimeFromAPI = (dateTimeString) => {
    return moment.tz(dateTimeString, 'UTC').tz('Asia/Kolkata');
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://niledutech.in/wp-json/ei-crm/v1/users/${userId}/details`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };

  const fetchTasks = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getTasks();
      const allTasks = Array.isArray(response) ? response : [];
      const currentUserId = currentUser.id || currentUser.user_id;
      const filteredTasks = allTasks.filter(task => 
        task.assigned_to == currentUserId || task.created_by == currentUserId
      );
      
      const tasksWithUserDetails = await Promise.all(filteredTasks.map(async task => {
        const [assignedToDetails, createdByDetails] = await Promise.all([
          fetchUserDetails(task.assigned_to),
          fetchUserDetails(task.created_by)
        ]);
        return {
          ...task,
          due_date: formatDateTimeForDisplay(task.due_date),
          created_at: formatDateTimeForDisplay(task.created_at),
          assigned_to_details: assignedToDetails,
          created_by_details: createdByDetails,
        };
      }));

      setTasks(tasksWithUserDetails);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to load tasks. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        const formattedUsers = response.users.map(user => ({
          id: user.id,
          name: user.display_name || user.username || user.email
        }));
        setUsers(formattedUsers);
      } else {
        console.error('Unexpected user data format:', response);
        message.error('Failed to load users: Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  }, []);

  useEffect(() => {
    fetchTasks();
    fetchUsers();
    const interval = setInterval(fetchTasks, 3600000);
    return () => clearInterval(interval);
  }, [fetchTasks, fetchUsers]);

  const handleCreate = async (values) => {
    try {
      const taskData = { 
        ...values, 
        due_date: formatDateTimeForAPI(values.due_date.toDate()),
        created_by: currentUser.id || currentUser.user_id
      };
      await createTask(taskData);
      message.success('Task created successfully');
      setModalVisible(false);
      form.resetFields();
      fetchTasks();
    } catch (error) {
      console.error('Error creating task:', error);
      message.error('Failed to create task. Please try again.');
    }
  };

  const handleUpdate = async (values) => {
    try {
      const currentTask = tasks.find(task => task.id === editingTask.id);
      if (!currentTask) {
        throw new Error('Task not found');
      }

      const updatedTaskData = {
        ...currentTask,
        ...values,
        due_date: values.due_date ? formatDateTimeForAPI(values.due_date.toDate()) : null
      };

      Object.keys(updatedTaskData).forEach(key => 
        updatedTaskData[key] === undefined && delete updatedTaskData[key]
      );

      await updateTask(editingTask.id, updatedTaskData);
      message.success('Task updated successfully');
      setModalVisible(false);
      setEditingTask(null);
      form.resetFields();
      fetchTasks();
    } catch (error) {
      console.error('Error updating task:', error);
      message.error('Failed to update task. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteTask(id);
      message.success('Task deleted successfully');
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
      message.error('Failed to delete task. Please try again.');
    }
  };

  const handleQuickStatusUpdate = async (id, newStatus) => {
    try {
      const taskToUpdate = tasks.find(task => task.id === id);
      if (!taskToUpdate) {
        throw new Error('Task not found');
      }

      const updatedTaskData = {
        ...taskToUpdate,
        status: newStatus,
        completed_at: newStatus === 'completed' ? formatDateTimeForAPI(moment().tz('Asia/Kolkata').toDate()) : null
      };

      await updateTask(id, updatedTaskData);
      message.success('Task status updated successfully');
      fetchTasks();
    } catch (error) {
      console.error('Error updating task status:', error);
      message.error('Failed to update task status. Please try again.');
    }
  };

  const openTaskModal = (task = null) => {
    setEditingTask(task);
    if (task) {
      form.setFieldsValue({
        ...task,
        due_date: parseDateTimeFromAPI(task.due_date),
      });
    } else {
      form.resetFields();
    }
    setModalVisible(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'orange';
      case 'in_progress':
        return 'blue';
      case 'completed':
        return 'green';
      default:
        return 'default';
    }
  };

  const getTaskStatus = (dueDate) => {
    const now = moment();
    const due = moment(dueDate, 'D-MMM-YYYY hh:mm A');
    const hoursDiff = due.diff(now, 'hours', true);

    if (hoursDiff <= 1 && hoursDiff > 0) {
      return 'dueNow';
    } else if (hoursDiff <= 3 && hoursDiff > 1) {
      return 'approaching';
    }
    return 'normal';
  };

  const renderTaskCard = (task) => {
    const taskStatus = getTaskStatus(task.due_date);
    const isDueNow = taskStatus === 'dueNow';
    const isApproaching = taskStatus === 'approaching';

    return (
      <StyledCard
        key={task.id}
        isDueNow={isDueNow}
        isApproaching={isApproaching}
        actions={[
          <Button key="edit" icon={<EditOutlined />} onClick={() => openTaskModal(task)}>Edit</Button>,
          <Button key="delete" icon={<DeleteOutlined />} onClick={() => handleDelete(task.id)} danger>Delete</Button>,
          task.status !== 'completed' && (
            <Button key="complete" icon={<CheckOutlined />} onClick={() => handleQuickStatusUpdate(task.id, 'completed')} type="primary">
              Complete
            </Button>
          )
        ].filter(Boolean)}
      >
        <TaskHeader>
          <TaskTitle level={4}>{task.title}</TaskTitle>
          <Badge status={getStatusColor(task.status)} text={task.status} />
          {isDueNow && (
            <DueBadge color="red" text={<span style={{ color: 'white', backgroundColor: 'red', padding: '2px 6px', borderRadius: '4px' }}>DUE NOW</span>} />
          )}
          {isApproaching && (
            <DueBadge color="yellow" text={<span style={{ color: 'white', backgroundColor: 'orange', padding: '2px 6px', borderRadius: '4px' }}>Approaching</span>} />
          )}
        </TaskHeader>
        <TaskDescription>{task.description}</TaskDescription>
        <Divider />
        <TaskMeta>
          <MetaItem>
            <ClockCircleOutlined style={{ marginRight: 8 }} />
            <Text>Due: {task.due_date}</Text>
          </MetaItem>
          <MetaItem>
            <Avatar 
              src={task.assigned_to_details?.profile_image_url} 
              icon={<UserOutlined />} 
              style={{ marginRight: 8 }}
            />
            <Text>Assigned to: {task.assigned_to_name}</Text>
          </MetaItem>
          <MetaItem>
            <Avatar 
              src={task.created_by_details?.profile_image_url} 
              icon={<UserOutlined />} 
              style={{ marginRight: 8 }}
            />
            <Text>Created by: {task.created_by_name}</Text>
          </MetaItem>
        </TaskMeta>
      </StyledCard>
    );
  };

  if (loading) {
    return (
      <PageWrapper>
        <Spin size="large" className="flex justify-center items-center h-screen" />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <div className="container mx-auto">
        <Row justify="space-between" align="middle" className="mb-8">
          <Col>
            <Title level={2}>Task Manager</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => openTaskModal()} size="large">
              Create New Task
            </Button>
          </Col>
        </Row>

        {error && (
          <Alert message="Error" description={error} type="error" showIcon className="mb-8" />
        )}

        <Row gutter={[16, 16]}>
          {tasks.map(task => (
            <Col xs={24} sm={12} md={8} key={task.id}>
              {renderTaskCard(task)}
            </Col>
          ))}
        </Row>

        <Modal
          title={editingTask ? "Edit Task" : "Create New Task"}
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setEditingTask(null);
            form.resetFields();
          }}
          footer={null}
        >
          <Form
            form={form}
            onFinish={editingTask ? handleUpdate : handleCreate}
            layout="vertical"
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the task title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input the task description!' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="due_date"
              label="Due Date and Time"
              rules={[{ required: true, message: 'Please select the due date and time!' }]}
            >
              <DatePicker 
                showTime 
                format="D-MMM-YYYY HH:mm"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="assigned_to"
              label="Assigned To"
              rules={[{ required: true, message: 'Please select a user!' }]}
            >
              <Select>
                {users.map(user => (
                  <Option key={user.id} value={user.id}>
                    {user.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: 'Please select the task status!' }]}
            >
              <Select>
                <Option value="pending">Pending</Option>
                <Option value="in_progress">In Progress</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {editingTask ? 'Update Task' : 'Create Task'}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </PageWrapper>
  );
};

TaskList.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display_name: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default TaskList;