import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Layout } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, DashboardOutlined } from '@ant-design/icons';
import Notifications from './Notifications';

const { Header } = Layout;

const Navbar = ({ logout, currentUser, fullyAuthenticated }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = useCallback(async (e) => {
    if (e.key === 'logout') {
      logout();
    } else if (e.key === 'profile') {
      navigate(`/user/${currentUser.id}`);
    }
    setOpen(false);
  }, [logout, navigate, currentUser]);

  const handleOpenChange = useCallback((flag) => {
    setOpen(flag);
  }, []);

  const menuItems = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'View Profile',
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ];

  const navMenuItems = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
      onClick: () => navigate('/dashboard'),
    },
    {
      key: 'social',
      label: 'InConnect',
      onClick: () => navigate('/social'),
    },
    {
      key: 'leads',
      label: 'Leads',
      onClick: () => navigate('/leads'),
    },
    {
      key: 'reminders',
      label: 'Reminders',
      onClick: () => navigate('/reminders'),
    },
    {
      key: 'tasks',
      label: 'Tasks',
      onClick: () => navigate('/tasks'),
    },
    {
      key: 'canned-responses',
      label: 'Canned Responses',
      onClick: () => navigate('/canned-responses'),
    },
  ];

  return (
    <Header className="bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <span onClick={() => navigate('/')} className="text-xl font-bold mr-8 cursor-pointer">InConnect CRM</span>
          <Menu mode="horizontal" items={navMenuItems} className="border-0" />
        </div>
        <div className="flex items-center">
          <Notifications fullyAuthenticated={fullyAuthenticated} />
          <Dropdown 
            menu={{ items: menuItems, onClick: handleMenuClick }}
            open={open}
            onOpenChange={handleOpenChange}
            trigger={['click']}
          >
            <div className="flex items-center cursor-pointer ml-4">
              <Avatar 
                src={currentUser.profile_image_url} 
                icon={<UserOutlined />}
              />
              <span className="ml-2 text-gray-700">
                {currentUser.display_name || 'User'}
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display_name: PropTypes.string,
    profile_image_url: PropTypes.string,
  }).isRequired,
  fullyAuthenticated: PropTypes.bool.isRequired,
};

export default React.memo(Navbar);