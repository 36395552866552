import React, { useState, useEffect, useCallback } from 'react';
import { getCannedResponses, createCannedResponse, updateCannedResponse, deleteCannedResponse } from '../apiService';
import { Table, Input, Button, message, Modal, Form, Popconfirm, Tooltip, Popover } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import debounce from 'lodash/debounce';

const { TextArea } = Input;

const CannedResponses = () => {
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingResponse, setEditingResponse] = useState(null);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filters, setFilters] = useState({
    search: '',
    orderby: 'title',
    order: 'ASC',
  });

  const fetchCannedResponses = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getCannedResponses({
        page: pagination.current,
        per_page: pagination.pageSize,
        ...filters,
      });
      
      if (Array.isArray(response)) {
        setResponses(response);
        setPagination(prev => ({
          ...prev,
          total: response.length,
        }));
      } else {
        console.error('Unexpected response format:', response);
        setError('Received unexpected data format from the server.');
      }
    } catch (error) {
      console.error('Error fetching canned responses:', error);
      setError('Failed to load canned responses. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [pagination.current, pagination.pageSize, filters]);

  useEffect(() => {
    fetchCannedResponses();
  }, [fetchCannedResponses]);

  const handleCreate = async (values) => {
    try {
      await createCannedResponse(values);
      message.success('Canned response created successfully');
      setModalVisible(false);
      form.resetFields();
      fetchCannedResponses();
    } catch (error) {
      console.error('Error creating canned response:', error);
      message.error('Failed to create canned response. Please try again.');
    }
  };

  const handleUpdate = async (values) => {
    try {
      await updateCannedResponse(editingResponse.id, values);
      message.success('Canned response updated successfully');
      setModalVisible(false);
      setEditingResponse(null);
      form.resetFields();
      fetchCannedResponses();
    } catch (error) {
      console.error('Error updating canned response:', error);
      message.error('Failed to update canned response. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCannedResponse(id);
      message.success('Canned response deleted successfully');
      fetchCannedResponses();
    } catch (error) {
      console.error('Error deleting canned response:', error);
      message.error('Failed to delete canned response. Please try again.');
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(prev => ({
      ...prev,
      orderby: sorter.field || 'title',
      order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    }));
  };

  const handleSearch = debounce((value) => {
    setFilters(prev => ({ ...prev, search: value }));
    setPagination(prev => ({ ...prev, current: 1 }));
  }, 300);

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      message.success('Copied to clipboard');
    }).catch(() => {
      message.error('Failed to copy. Please try again.');
    });
  };

  const renderMarkdownPreview = (content) => {
    const previewLength = 300;
    const previewContent = content.length > previewLength 
      ? content.substring(0, previewLength) + '...' 
      : content;

    return (
      <div className="markdown-preview" style={{ maxWidth: '400px', maxHeight: '300px', overflow: 'auto' }}>
        <ReactMarkdown>{previewContent}</ReactMarkdown>
      </div>
    );
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      render: (text, record) => (
        <Popover
          content={renderMarkdownPreview(record.content)}
          title="Response Preview"
          trigger="hover"
          overlayStyle={{ maxWidth: '400px' }}
        >
          <span style={{ cursor: 'pointer', borderBottom: '1px dashed #999' }}>{text}</span>
        </Popover>
      ),
    },
    {
      title: 'Content',
      dataIndex: 'content',
      ellipsis: true,
    },
    {
      title: 'Actions',
      render: (_, record) => (
        <>
          <Button onClick={() => {
            setEditingResponse(record);
            form.setFieldsValue(record);
            setModalVisible(true);
          }} className="mr-2">
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this canned response?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
          <Tooltip title="Copy content">
            <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(record.content)} className="ml-2" />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Canned Responses</h1>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      
      <Button onClick={() => {
        setEditingResponse(null);
        form.resetFields();
        setModalVisible(true);
      }} type="primary" className="mb-4">
        Create New Response
      </Button>

      <Input.Search
        placeholder="Search responses"
        onSearch={handleSearch}
        className="mb-4"
        allowClear
      />

      <Table
        columns={columns}
        dataSource={responses}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />

      <Modal
        title={editingResponse ? "Edit Canned Response" : "Create Canned Response"}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingResponse(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={editingResponse ? handleUpdate : handleCreate}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[{ required: true, message: 'Please input the content!' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingResponse ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CannedResponses;